import React, { useState, useContext, useEffect, useRef } from "react";
import {
  AppBar,
  Typography,
  ThemeProvider,
  Grid,
  Toolbar,
  Button,
  Avatar,
  Tabs,
  Tab,
  Box,
  Slide,
  Menu,
  MenuItem,
  InputBase,
  SvgIcon,
  IconButton,
  Checkbox,
  Grow,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  Badge
} from "@mui/material";
import PropTypes from "prop-types";
import axiosInstance from "../utils/AxiosInstance";
import theme from "../ui/Theme";
import CloseIcon from "@mui/icons-material/Close";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "@mui/material/Fade";
import logonew from "../../assets/logonew.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../hooks/context/LanguageContext";
import { Link } from "react-router-dom";
import { AuthContext } from "../../hooks/context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import translationImage from "../../assets/categories/translation_2282289.png";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { CartContext } from "../../hooks/context/CartContext";
import Loading from "../common/Loading";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClickBtt = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  return (
    <Fade in={trigger}>
      {/* <Bookload/> */}
      <Box
        onClick={handleClickBtt}
        role="presentation"
        sx={{ position: "fixed", bottom: 25, right: 20, zIndex: 50 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Navbar(props) {
  // const { addedBooks } = props; 
  //console.log("addedBooks ID:", addedBooks); 
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState(0);
  const [anchorElNavCb, setAnchorElNavCb] = useState(null);
  const [anchorElHam, setAnchorElHam] = useState(null);
  const [anchorElHamCb, setAnchorElHamCb] = useState(null);
  const [anchorElTranslate, setAnchorElTranslate] = useState(null);
  const [anchorUserMenu, setAnchorUserMenu] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false); // For the drawer
  const { t, i18n } = useTranslation("nav");
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryBooks, setSelectedCategoryBooks] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const { language, changeLanguage } = useContext(LanguageContext);
  const { isAuthenticated, user, logout, checkAuth, loading: authLoading } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [books, setBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Initial state is an empty string
  const [suggestions, setSuggestions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const inputRef = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const itemRefs = useRef([]);
  const [previousTabValue, setPreviousTabValue] = useState(0);
  const [isReadTabOpen, setIsReadTabOpen] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(true);

  const { cartCount } = useContext(CartContext);

  const userId = user?.id;

  const handleIconClick = () => {
    inputRef.current.focus();
  };

  const handleFavoriteClick = () => {
    handleCloseUserMenu();
    navigate("/user-dashboard", { state: { selectedTab: 1 } });    
  };


  const handleCategoryChange = (categoryId) => {
    handleCloseCategoryMenu();
    navigate("/books", { state: { categoryId } });    
    setValue(value); // Close the menu after navigation
  };

  const handleNavAllBooks = () => {
    setAnchorEl(null);
    setValue(value);
    setIsReadTabOpen(false);

    navigate("/books", {
      state: {
        level: "",
        categoryId: "",
        subcategoryId: "",
        sortBy: "",
      },
    });
  };


  useEffect(() => {
    const fetchCategories = async () => {      
      try {
        const response = await axiosInstance.get("/books/categories", {
          headers: {
            "Accept-Language": language,
          },
        });
        setCategories(response.data.categories);
        setLevels(response.data.levels);
      } catch (error) {
        //console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [language]);

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      if (event.key === "ArrowDown") {
        setHighlightedIndex((prevIndex) =>
          prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === "ArrowUp") {
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
        );
      } else if (event.key === "Enter" && highlightedIndex >= 0) {
        handleSuggestionClick(suggestions[highlightedIndex]);
      }
    }
  };

  useEffect(() => {
    // Reset highlighted index when suggestions change
    setHighlightedIndex(-1);
  }, [suggestions]);

  useEffect(() => {
    // Scroll the highlighted item into view
    if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
      itemRefs.current[highlightedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [highlightedIndex]);
  
  const handleTabClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setIsReadTabOpen(true);
  };

  const handleDashboardClick = () => {
    handleCloseUserMenu();
    if (user.role === "admin") {
      navigate("/admin");
    } else if (user.role === "editor") {
      navigate("/editor");
    } else {
      navigate("/user-dashboard");
    }    
  };

  const handleCloseCategoryMenu = () => {
    setAnchorEl(null);
    setValue(previousTabValue);
    setIsReadTabOpen(false);
  };

  const handleLogout = async () => {
    //console.log("Logout function triggered");
    handleCloseUserMenu();
    await logout(() => setOpenSnackbar(true));
    await checkAuth();
    setTimeout(() => {
      navigate("/");
      setValue("0");
    }, 1000);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleSearchClick = () => {
    setShowInput(true); // Show the input field
  };

  const handleCloseSearch = () => {
    setShowInput(false);
    setSearchTerm("");
  };

  //User Menu
  const handleCloseUserMenu = () => {
    setAnchorUserMenu(null);
  };

  const handleOpenUserMenu = (e) => {
    setAnchorUserMenu(e.currentTarget);
  };

  //Hamburger Menu
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  //Nav Tabs
  const handleTabChange = (event, newValue) => {
    setPreviousTabValue(value);
    setValue(newValue);
    localStorage.setItem("TabValue", newValue);
    if (newValue === 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  

 
//  useEffect(() => {
//   const fetchInitialCartCount = async () => {
    
//     try {
//       const response = await axiosInstance.get(`/purchase/getCartCount/${userId}`);
//       setCartCount(response.data.count);
      
//     } catch (error) {
//       //console.error('Error fetching initial cart count:', error);
//     }
//   }

//   fetchInitialCartCount();
// });


  const fetchCartCountAndRedirect = async () => {
    try {
      // const response = await axiosInstance.get(`/purchase/getCartCount/${userId}`);
      // const count = response.data.count;
      // setCartCount(count);  // Update cart count in the badge

    //   if (count > 0) {
    //     // Redirect to the dashboard only if count is greater than 0
    //     // navigate("/addcart", { state: { selectedTab: 2 } });
        navigate("/addcart");
    //   } else {
    //     //console.log("Cart is empty!");
    //   }
      
    } catch (error) {
      //console.error('Error fetching cart count:', error);
    }
  };


  useEffect(() => {
    const storedValue = localStorage.getItem("TabValue");
    setPreviousTabValue(storedValue);
    setValue(storedValue);
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!userId) {
        return; 
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/profile`
        );
        const data = await response.json();        

        if (response.ok) {
          setProfileImage(data.profile_picture || "");
        } else {
          //console.error("Failed to fetch user profile:", data.message);
        }
      } catch (err) {
        //console.error("Error fetching user profile:", err);
      }
    };

    fetchUserProfile();
  }, [userId]);

  //Translate
  const handleTranslateOpen = (event) => {
    setAnchorElTranslate(event.currentTarget);
  };

  const handleCloseTranslate = () => {
    setAnchorElTranslate(null);
  };

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
    setAnchorElTranslate(null);
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    //  //console.log("valueee-----", value);

    if (value.length > 0) {
      // Search only if more than 2 characters
      //console.log("0000", value);
      try {
        // const response = await axios.get(`/api/books/search?q=${value}`);
        // setSuggestions(response.data);
        const response = await axiosInstance.get(`/books/search/${value}`);
        //  //console.log("response-------", response);
        setSuggestions(response.data);
      } catch (error) {
        //console.error("Error fetching search suggestions:", error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleLogoClick = () => {
    window.location.href = "/";
    setValue("0");
    localStorage.setItem("TabValue", 0);
  };

  const handleLogin = () => {
    setValue(0);
    localStorage.setItem("TabValue", 0);
  };

  const handleSuggestionClick = (book) => {
    //console.log("Clicked Book:", book);
    setSuggestions([]);
    navigate(`/book/${book.book_id}`, { state: { book } });
  };

  //console.log("Book Details:", books);

  const listItemStyle = {
    color: "black",
    "&:hover": {
      color: "lightgreen",
    },
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <IconButton
          edge="center"
          color="inherit"
          aria-label="close"
          onClick={toggleDrawer(false)}
        >
          <CloseIcon />
        </IconButton>
        <ListItem button component={Link} to="/" sx={listItemStyle}>
          <ListItemText primary={t("tab.tab1")} />
        </ListItem>
        <ListItem button component={Link} to="/books" sx={listItemStyle}>
          <ListItemText primary={t("tab.tab2.main")} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/create/book/form"
          sx={listItemStyle}
        >
          <ListItemText primary={t("tab.tab3")} />
        </ListItem>
        <ListItem button component={Link} to="/about" sx={listItemStyle}>
          <ListItemText primary={t("tab.tab4")} />
        </ListItem>
        {!isAuthenticated ? (
          <>
            <ListItem
              button
              component={Link}
              to="/auth/login"
              sx={listItemStyle}
            >
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/auth/register"
              sx={listItemStyle}
            >
              <ListItemText primary="Register" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem
              button
              component={Link}
              to="/user-dashboard"
              sx={listItemStyle}
            >
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              onClick={handleLogout}
              sx={listItemStyle}
              value="0"
            >
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  if (loading || authLoading) {
    return (
        <Loading/>
      )
    }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <HideOnScroll {...props}>
          <AppBar position="fixed" sx={{ backgroundColor: "#ffffffff" }}>
            <Toolbar>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={6} md={2} sx={{ alignItems: "center" }}>
                  <Box                    
                    component="img"
                    src={logonew}
                    sx={{ width: "50px", height: "50px", cursor: "pointer" }}
                    onClick={handleLogoClick}
                  />
                </Grid>

                {!showInput && (
                  <Grid
                    item
                    container
                    md={6}
                    justifyContent="center"
                    sx={{ display: { xs: "none", md: "flex" } }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleTabChange}
                      textColor="primary"
                      indicatorColor="primary"
                      sx={{ "& .MuiTab-root": { color: "text.primary" } }}
                    >
                      <Tab
                        value="0"
                        component={Link}
                        to="/"
                        label={t("tab.tab1")}
                      />
                      <Tab
                        value="1"
                        component={Link}
                        to="/books"
                        label={
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {t("tab.tab2.main")}
                            {isReadTabOpen ? (
                              <ExpandLessIcon fontSize="small" />
                            ) : (
                              <ExpandMoreIcon fontSize="small" />
                            )}
                          </Box>
                        }
                        onClick={handleTabClick}
                      />
                      <Tab
                        value="2"
                        component={Link}
                        to="/create/book/form"
                        label={t("tab.tab3")}
                      />
                      <Tab
                        value="3"
                        component={Link}
                        to="/about"
                        label={t("tab.tab4")}
                      />
                    </Tabs>
                  </Grid>
                )}

                {!showInput && (
                  <Grid
                    item
                    md={4}
                    sx={{
                      display: { xs: "none", md: "flex" },
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    gap={2}
                  >
                    {!isAuthenticated ? (
                      <>
                        <Button
                          component={Link}
                          variant="contained"
                          to="/auth/login"
                          color="primary"
                          onClick={handleLogin}
                        >
                          {t("button.login")}
                        </Button>
                        <Button
                          component={Link}
                          variant="outlined"
                          to="/auth/register"
                          color="primary"
                          sx={{ mr: "10px" }}
                          onClick={handleLogin}
                        >
                          {t("button.register")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Avatar
                          sx={{ cursor: "pointer", width: 50, height: 50 }}
                          onClick={handleOpenUserMenu}
                          src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/user/profile_pic/${profileImage}`}
                        >
                          {user.name?.[0]}
                        </Avatar>

                        <Box>
                          <Box width={"auto"} height={"20px"}>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              sx={{
                                color: "black",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {t("button.vanakam")} !
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              sx={{
                                color: "black",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {user.first_name}
                            </Typography>
                          </Box>
                        </Box>
                        <Box></Box>
                        <Menu
                          anchorEl={anchorUserMenu}
                          open={Boolean(anchorUserMenu)}
                          onClose={handleCloseUserMenu}
                          sx={{ marginTop: "15px" }}
                        >
                          <MenuItem onClick={handleDashboardClick}>
                            <ListItemIcon sx={{ color: "#000" }}>
                              <DashboardOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                          </MenuItem>
                          {user.role === "user" && (
                            <MenuItem onClick={handleFavoriteClick}>
                              <ListItemIcon sx={{ color: "#000" }}>
                                <StarBorderOutlinedIcon />
                              </ListItemIcon>
                              <ListItemText primary="Favorite Books" />
                            </MenuItem>
                          )}
                          <MenuItem onClick={handleLogout}>
                            <ListItemIcon sx={{ color: "#000" }}>
                              <PowerSettingsNewOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                          </MenuItem>
                        </Menu>
                      </>
                    )}

                    
                      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>                        
                        <Badge badgeContent={cartCount} color="primary" showZero >
                          <ShoppingCartIcon onClick={fetchCartCountAndRedirect} sx={{ color: '#000', fontSize: '30px',cursor:'pointer' }} />
                        </Badge>
                      </Box> */}
                    

                    <IconButton disableRipple onClick={handleTranslateOpen}>
                      <img
                        src={translationImage}
                        alt="translate icon"
                        style={{ width: 20, height: 20 }}
                      />
                    </IconButton>
                    <Menu
                      sx={{ marginTop: "16px" }}
                      anchorEl={anchorElTranslate}
                      open={Boolean(anchorElTranslate)}
                      onClose={handleCloseTranslate}
                    >
                      <MenuItem onClick={() => handleLanguageChange("ta")}>
                        தமிழ்
                      </MenuItem>
                      <MenuItem onClick={() => handleLanguageChange("en")}>
                        English
                      </MenuItem>
                    </Menu>
                    <IconButton disableRipple onClick={handleSearchClick}>
                      <SvgIcon fontSize="medium" sx={{ color: "text.primary" }}>
                        <SearchIcon />
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                )}

                <Grid
                  item
                  xs={6}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={toggleDrawer(true)}
                    sx={{
                      color: "text.primary",
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                  >
                    {list()}
                  </Drawer>
                </Grid>

                {showInput && (
                  <Grid
                    item
                    md={8}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        backgroundColor: "#F0F0F0",
                        borderRadius: "10px",
                        alignItems: "center",
                        width: "100%",
                        padding: "2px",
                      }}
                    >
                      <SvgIcon
                        fontSize="small"
                        sx={{
                          color: "text.primary",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={handleIconClick}
                      >
                        <SearchIcon />
                      </SvgIcon>
                      <InputBase
                        inputRef={inputRef}
                        sx={{
                          flex: 1,
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        placeholder={t("button.searchBook")}
                        inputProps={{ "aria-label": "search" }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                      />
                      {searchTerm.length > 0 && suggestions.length > 0 && (
                        <Paper
                          sx={{
                            position: "absolute", // Position it absolutely to align with the input field
                            top: "100%", // Align just below the input field
                            left: 525,
                            right: 0,
                            maxWidth: 970,
                            zIndex: 1, // Ensure it appears on top
                            maxHeight: 200, // Optional: Limit the height of the suggestions box
                            overflowY: "auto", // Add scroll if there are too many suggestions
                          }}
                        >
                          <List>
                            {suggestions.map((book, index) => (
                              <ListItem
                                button
                                book={book}
                                key={book.book_id}
                                selected={index === highlightedIndex}
                                onClick={() => handleSuggestionClick(book)}
                                ref={(el) => (itemRefs.current[index] = el)}
                              >
                                <ListItemText primary={book.title} />
                                {/* {//console.log("Book Image:", book.image)} */}
                              </ListItem>
                            ))}
                          </List>
                        </Paper>
                      )}

                      <IconButton onClick={handleCloseSearch}>
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        </HideOnScroll>

        <Menu
          id="menu-popup"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseCategoryMenu}
          sx={{ marginTop: "10px" }}
        >
          <MenuItem onClick={handleNavAllBooks}>
            {/* All Books */} {t("button.all")}
          </MenuItem>
          {categories.map((category) => (
            <MenuItem
              key={category.category_id}
              onClick={() => {
                handleCategoryChange(category.category_id);
                setAnchorEl(null);
                // handleClose();
              }}
            >
              {category.category_name}
            </MenuItem>
          ))}
        </Menu>

        <Box id="back-to-top-anchor" />
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{
              width: "100%",
              position: "relative",
              left: "650px",
              top: "60px",
            }}
          >
            Logout successful!
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default Navbar;
