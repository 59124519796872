import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    IconButton,
    Paper,
    Typography,
    TextField,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Alert,
    CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from "@mui/icons-material/Edit";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import "react-resizable/css/styles.css";
import ScenesDialog from "./ScenesDialog";
import ItemsDialog from "./ItemsDialog";
import TextEditorDialog from "./TextEditorDialog";
import PageSelectorGrid from "./PageSelectorGrid";
import background from "../../assets/Book_background.jpg";
import book3 from "../../assets/bookBg.png";
import book2 from "../../assets/book2.svg";
import PageViewPopup from "../bookCreator/PageViewPop";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axiosInstance from "../utils/AxiosInstance";
import LayersIcon from "@mui/icons-material/Layers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Document, Packer, Paragraph, TextRun, ImageRun } from "docx";
import { saveAs } from "file-saver";
import axios from "axios";
import { AuthContext } from "../../hooks/context/AuthContext";
import Navbar from "../layout/Navbar";
import pencil from '../../assets/pencil.png'
import tamillogo from '../../assets/tamillogo.png'

import { Rnd } from 'react-rnd';

const coverContent = (book) => ({
    id: Date.now(),
    pageNumber: 1,
    backgroundColor: "#ffffff",
    texts: [
        { id: "title", text: `<strong>${book?.title || "Untitled"}</strong>`, position: { x: 214, y: 535 }, size: 30,  },
        { id: "author", text: `<strong>-${book?.author_name || "Unknown"}</strong>`, position: { x: 214, y: 565 }, size: 28 },
    ],
    images: [
        {
            id: 'cover',
            src: `${process.env.REACT_APP_BOOK_COVER_BASE_URL}${book?.cover_image_url}`,
            position: { x: 0, y: 0 },
            width: '550px'
        },
    ],
});

const App = () => {
    const [newItemsExist, setNewItemsExist] = useState(false);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [originalPages, setOriginalPages] = useState([]);
    const [initialPages, setInitialPages] = useState([]);
    const location = useLocation();
    const { book } = location.state || {};
    //console.log("Location state:", location.state);
    //console.log('book title', book)
    const bookId = book.book_id;
    const [pages, setPages] = useState([
        {
            // id: bookId,            
            pageNumber: 1,
            backgroundColor: "",
            backgroundImage: "",
            texts: [],
            images: [],
        },
    ]);


    

    // useEffect(() => {
    //     setInitialPages(pages);
    // }, []);

    useEffect(() => {
        if (bookId) {
            // Update the page state with the bookId once it's available
            setPages((prevPages) =>
                prevPages.map((page) => ({ ...page, id: bookId }))
            );
        }
    }, [bookId]);
    const [anchorElAddPages, setAnchorElAddPages] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [openScenesDialog, setOpenScenesDialog] = useState(false);
    const [openItemsDialog, setOpenItemsDialog] = useState(false);
    const [openTextEditorDialog, setOpenTextEditorDialog] = useState(false);
    const [deletedItems, setDeletedItems] = useState([]);
    const [redoItems, setRedoItems] = useState([]);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    // const [selectedImage, setSelectedImage] = useState(null);
    // const [storyTitle, setStoryTitle] = useState('')

    const [preview, setPreview] = useState(false);

    const [openPageView, setOpenPageView] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmittingPdf, setIsSubmittingPdf] = useState(false);

    const [openPageGridDialog, setOpenPageGridDialog] = useState(false);
    const { isAuthenticated, user, logout, checkAuth } = useContext(AuthContext);
    const userId = user?.id
    const navigate = useNavigate();
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [selectedTextId, setSelectedTextId] = useState(null);

    const paperRefs = useRef([]);
    const [hoveredTextId, setHoveredTextId] = useState(null);

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [itemType, setItemType] = useState(null);
    const [currentPageId, setCurrentPageId] = useState(null);

    const [capturedPages, setCapturedPages] = useState([]);

    const [isHovered, setIsHovered] = useState(false);

    const [open, setOpen] = useState(false);
    const [selectedText, setSelectedText] = useState(null);
    const [updatedText, setUpdatedText] = useState("");

    const [selectedTextIndex, setSelectedTextIndex] = useState(null);

    const [isDragging, setIsDragging] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);

    const [selectedPageIndex, setSelectedPageIndex] = useState(null); // Index of selected page


    const [selectedPageId, setSelectedPageId] = useState(null);
    const [currentText, setCurrentText] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);

    //console.log("page:", pages);
    //console.log("seleceted page", selectedPage);
    //console.log("currentPID", currentPageId);
    //console.log("selectedPID", selectedPageId);
    
    


    // Function to handle opening the dialog
    // const handleOpenDialog = (text) => {
    //     setSelectedText(text);
    //     setUpdatedText(text.text); // Set the current text for editing
    //     setOpen(true);
    // };


    const handleCloseDialog = () => {
        setEditDialogOpen(false);
        setSelectedPage(null);
        setCurrentText(null);
    };

    const handleSaveText = (updatedText) => {
        console.log(updatedText,"buss");
        
        const updatedPages = pages.map((page) =>
            page.page_id === selectedPageId
                ? {
                    ...page,
                    texts: page.texts.map((text) =>
                        text.id === updatedText.id
                            ? { ...updatedText, position: text.position }
                            : text
                    ),
                }
                : page
        );
        setPages(updatedPages);
        setEditDialogOpen(false);
    };
    const handleImageClick = (imageId) => {
        setSelectedImageId((prevSelectedImageId) =>
            prevSelectedImageId === imageId ? null : imageId
        );
    };

    const handleTextClick = (pageId, textId) => {        
        
        setSelectedTextId(textId);
        setSelectedPageId(pageId);
    };

    const handleOpenPageGridDialog = () => {
        setOpenPageGridDialog(true);
        //console.log("page contents:", pages);
    };

    const handleCapturePageView = async () => {
        const capturedImages = [];

        for (let i = 0; i < paperRefs.current.length; i++) {
            const paperRef = paperRefs.current[i];

            if (paperRef) {
                const canvas = await html2canvas(paperRef, {
                    scale: 1,
                    useCORS: true,
                    width: 501,
                    height: 614,
                    scrollX: 0,
                    scrollY: 0,
                    windowWidth: 501,
                    windowHeight: 614,
                });

                const imgData = canvas.toDataURL('image/png', 0.5);
                capturedImages.push({
                    image: imgData,
                    pageNumber: i + 1,
                });
            }
        }

        setCapturedPages(capturedImages);
        setOpenPageGridDialog(true);
    };

    const handleClosePageGridDialog = () => {
        setOpenPageGridDialog(false);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const versoContent = {
        id: Date.now(),
        pageNumber: 2,
        backgroundColor: "#ffffff",
        texts: [
            { id: "title", text: `Book Name: ${book.title}`, position: { x: 10, y: 15 } },
            { id: "author", text: `Author Name: ${book.author_name}`, position: { x: 10, y: 45 } },
            { id: "isbn", text: "ISBN:", position: { x: 10, y: 75 } },
            { id: "edition", text: "First Edition in Tamil:2024", position: { x: 10, y: 125 } },
            { id: "publich", text: "<b>Published By:</b>", position: { x: 10, y: 155 } },
            { id: "tamil", text: "Tamil Ezhuthapadi Publications and Research <b>www.tamilezhuthapadi.org</b>", position: { x: 10, y: 185 } },
            { id: "address", text: "296,Mehta Nagar,", position: { x: 10, y: 235 } },
            { id: "pincode", text: "Chennai - 600069.", position: { x: 10, y: 260 } },
            { id: "state", text: "TamilNadu , India", position: { x: 10, y: 285 } },
            { id: "print", text: "<b>Printed By</b>", position: { x: 10, y: 328 } },
            { id: "printers", text: "Vanakkam Printers,Chennai,India", position: { x: 10, y: 350 } },
            { id: "description", text: "All Rights Reserved. The text and illustration are licensed under CC-BY 4.0, empowering you to translate the digital version of this book, cre-atively remix illustration ,and craft new series - all accessible freely, on   tamilezhuthapadi.org. However, it's esential to respect the integrity of    this work, therefore,no part of this book should be mechanically reprod-uced by any other printers or publishers", position: { x: 10, y: 420 } },
        ],
        images: [],
    };

    const copyrightContent = {
        id: Date.now(),
        pageNumber: pages.length + 1,
        backgroundColor: "#ffffff",
        texts: [
            {
                id: "copyright-text",
                text: "This book marks a pioneering effort by Tamil Ezhuthapadi Publications. All rights are reserved with the exception that the content within may be translated into another language solely in E-book format only under Cr-eative Commons licenses. In utilizing these materials, we kindly request authors to duly acknowledge their work, outline any modifications made, and provide full terms of use and attributions.",
                position: { x: 10, y: 15 },
            },
            { id: "story", text: "<b>Story Attribution:</b>", position: { x: 10, y: 200 } },
            { id: "book", text: `<b>This story, ${book.title} is authored by</b> `, position: { x: 10, y: 230 } },
            { id: "author", text: `<b>${book.author_name}</b>`, position: { x: 10, y: 250 } },
            { id: "copyrights", text: "<b>Copyright 2024 by TamilEzhuthapadi </b>", position: { x: 10, y: 300 } },
            { id: "copyrights", text: "<b> Publications</b>", position: { x: 10, y: 320 } },
            { id: "info", text: 'For more information, visit us at <a href="https://storybuzz.org/" style="color: blue;" target="_blank">https://storybuzz.org/</a>', position: { x: 10, y: 370 }, color: 'red' },
            { id: "inquries", text: "For inquiries, please email us: support@storybuzz.com", position: { x: 10, y: 400 }, color: 'red' },
        ],
        images: [
            { id: 'pencil', src: pencil, position: { x: '0', y: '450' }, width: '550px' },
            { id: 'logo', src: tamillogo, position: { x: '350', y: '220' }, height: '130px' }
        ],
    };

    const addVersoContent = () => {
        setPages((prevPages) => {
            const updatedPages = [
                prevPages[0],
                versoContent,
                ...prevPages.slice(1),
            ];
                
            return updatedPages.map((page, index) => ({
                ...page,
                pageNumber: index + 1,
            }));
        });
        setNewItemsExist(true);
        handleCloseAddPages()
    };

    const addCopyrightContent = () => {
        setPages([...pages, copyrightContent]);
        setNewItemsExist(true);
        handleCloseAddPages()
    };

    const handlePageGridClick = (page) => {
        const pageElement = document.getElementById(`page-${page.page_id}`);
        //console.log("pageElement", pageElement);

        if (pageElement) {
            // Scroll to the corresponding page in the second image
            window.scrollTo({
                top: pageElement.offsetTop,
                behavior: "smooth",
            });

        }

        handleClosePageGridDialog();
    };

    const handleOpenPageView = () => {
        // sendArrayToBackend();
        setPreview(!preview);
    };

    const handleClosePageView = () => {
        setOpenPageView(false);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, pages.length - 1));
    };

    const addPage = () => {
        const newPageId = Date.now();
        setPages([...pages, { page_id: newPageId, pageNumber: pages.length + 1, backgroundColor: '', backgroundImage: '' }]);
        setNewItemsExist(true);
        setIsSubmitEnabled(true);
    };

    const handleMenuOpen = (event, page) => {
        setAnchorEl(event.currentTarget);
        setSelectedPage(page);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedPage(null);
    };

    const updatePageIds = (pages) => {
        return pages.map((page, index) => ({
            ...page,
            // id: index + 1,
            pageNumber: index + 1
        }));
    };


    const moveToTop = (page) => {
        const index = pages.findIndex((p) => p.page_id === page.page_id);
        if (index > 0) {
            const updatedPages = [...pages];

            [updatedPages[index - 1], updatedPages[index]] = [updatedPages[index], updatedPages[index - 1]];
            setPages(updatePageIds(updatedPages));
        }
        handleMenuClose();
    };

    const moveDown = (page) => {
        const index = pages.findIndex((p) => p.page_id === page.page_id);
        if (index < pages.length - 1) {
            const updatedPages = [...pages];

            [updatedPages[index], updatedPages[index + 1]] = [updatedPages[index + 1], updatedPages[index]];
            setPages(updatePageIds(updatedPages));
        }
        handleMenuClose();
    };

    const insertPage = (page) => {
        const newPageId = Date.now();
        const newPage = {page_id: newPageId, pageNumber: pages.length + 1, backgroundColor: '', backgroundImage: '' };
        const index = pages.findIndex((p) => p.page_id === page.page_id);
        const updatedPages = [...pages];
        updatedPages.splice(index + 1, 0, newPage);
        setPages(updatePageIds(updatedPages));
        handleMenuClose();
    };

    const deletePage = (page) => {
        // if (page.page_id === 1) {
        //     return;
        // }
        const updatedPages = pages.filter((p) => p.page_id !== page.page_id);
        setPages(updatePageIds(updatedPages));
        setNewItemsExist(false);
        handleMenuClose();
    };


    const fetchPages = async () => {
        try {
            if (bookId) {
                const response = await axiosInstance.get(`/create/${bookId}/page`); 
                //console.log(response.data?.length, "my");
                
                if (response.data?.length === 0) {
                    setPages([coverContent(book)]);
                    setNewItemsExist(true);    
                } else{                
                    //console.log("dale", response.data);
                    
                setPages([...response.data]);
                setOriginalPages(response.data);
                setNewItemsExist(false);
            }
            }
        } catch (err) {
            if (err.response.status === 401) {
                window.location.href = '/auth/login';
            }
            //console.log("Failed to fetch book details");
        }
    };

    useEffect(() => {
        fetchPages();
    }, [bookId]);


    const openScenesDialogBox = (page) => {
        setSelectedPage(page);
        setOpenScenesDialog(true);
    };

    const closeScenesDialogBox = () => {
        setOpenScenesDialog(false);
        setSelectedPage(null);
    };

    const handleBackgroundColorSelect = (color) => {
        if (selectedPage) {
            const updatedPages = pages.map((page) =>
                page.page_id === selectedPage.page_id
                    ? { ...page, backgroundColor: color, backgroundImage: null }
                    : page
            );
            setPages(updatedPages);
        }
    };

    const handleBackgroundImageSelect = (image) => {
        if (selectedPage) {
            const updatedPages = pages.map((page) =>
                page.page_id === selectedPage.page_id
                    ? { ...page, backgroundImage: image, backgroundColor: null }
                    : page
            );
            setPages(updatedPages);

        }
    };


    const openItemsDialogBox = (page) => {
        setSelectedPage(page);
        setOpenItemsDialog(true);
    };

    const closeItemsDialogBox = () => {
        setSelectedPage(null);
        setOpenItemsDialog(false);
    };

    const handleImageSelect = (url) => {
        if (selectedPage) {
            const newId = Date.now();
            const updatedPages = pages.map((page) =>
                page.page_id === selectedPage.page_id
                    ? {
                        ...page,
                        images: [
                            ...(page.images || []),
                            { id: newId, src: url, position: { x: 0, y: 0 } },
                        ],
                    }
                    : page
            );

            const lastAddedImage = {
                type: "add-image",
                pageId: selectedPage.page_id,
                image: { id: newId, src: url, position: { x: 0, y: 0 } },
            };
            setUndoStack((prev) => [...prev, lastAddedImage]);
            setRedoStack([]);
            setPages(updatedPages);
            setNewItemsExist(true);
        }
    };

    const openTextEditorDialogBox = (page) => {
        setSelectedPage(page);
        setOpenTextEditorDialog(true);
    };

    const closeTextEditorDialogBox = () => {
        setOpenTextEditorDialog(false);
        setSelectedPage(null);
        // setEdit({
        //     ...edit,
        //     isEdit: false
        // })
    };

    const handleAddText = ({ text, textSize, textColor, isBold, isItalic, alignment }) => {        
        //console.log("ala",textSize, textColor, alignment);

        

        if (selectedPage) {
            const newId = Date.now();
            const updatedPages = pages.map((page) =>
                page.page_id === selectedPage.page_id
                    ? {
                        ...page,
                        texts: [
                            ...(page.texts || []),
                            {
                                id: newId,
                                text,
                                size: textSize,
                                color: textColor,
                                isBold,
                                isItalic,
                                position: { x: 0, y: 0 },                                
                            },
                        ],
                    }
                    : page
            );
            const lastAddedText = {
                type: "add-text",
                pageId: selectedPage.page_id,
                text: {
                    id: newId,
                    text,
                    size: textSize,
                    color: textColor,
                    isBold,
                    isItalic,
                    position: { x: 0, y: 0 },                    
                },
            };
            setUndoStack((prev) => [...prev, lastAddedText]);
            setRedoStack([]);
            setPages(updatedPages);
            setNewItemsExist(true);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && !event.shiftKey && event.code === "KeyZ") {
                if (undoStack.length > 0) {
                    const lastAction = undoStack.pop();
                    setUndoStack([...undoStack]);
                    setRedoStack((prev) => [...prev, lastAction]);

                    const updatedPages = pages.map((page) => {
                        if (page.page_id === lastAction.pageId) {
                            if (lastAction.type === "add-image") {
                                const updatedImages = page.images.filter(
                                    (img) => img.id !== lastAction.image.id
                                );
                                return { ...page, images: updatedImages };
                            } else if (lastAction.type === "add-text") {
                                const updatedTexts = page.texts.filter(
                                    (txt) => txt.id !== lastAction.text.id
                                );
                                return { ...page, texts: updatedTexts };
                            }
                        }
                        return page;
                    });

                    setPages(updatedPages);
                    setNewItemsExist(true);
                }
            } else if (event.ctrlKey && event.shiftKey && event.code === "KeyZ") {
                if (redoStack.length > 0) {
                    const lastAction = redoStack.pop();
                    setRedoStack([...redoStack]);
                    setUndoStack((prev) => [...prev, lastAction]);

                    const updatedPages = pages.map((page) => {
                        if (page.page_id === lastAction.pageId) {
                            if (lastAction.type === "add-image") {
                                return { ...page, images: [...page.images, lastAction.image] };
                            } else if (lastAction.type === "add-text") {
                                return { ...page, texts: [...page.texts, lastAction.text] };
                            }
                        }
                        return page;
                    });

                    setPages(updatedPages);
                    setNewItemsExist(true);
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [undoStack, redoStack, pages]);

    const sendArrayToBackend = async () => {
        setIsLoading(true);
        try {
            // const response = await fetch(
            //     `${process.env.REACT_APP_API_BASE_URL}/create/${bookId}/page`,
            //     {
            //         method: "POST",
            //         headers: {
            //             "Content-Type": "application/json",
            //         },
            //         body: JSON.stringify({
            //             bookId,
            //             pages,
            //         }),
            //     }
            // );

            const response = await axiosInstance.post(
                `/create/${bookId}/page`,
                {
                    bookId,
                    pages,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                const data = await response.data;
                //console.log("Array sent successfully:", data);
                fetchPages()
                setInitialPages(pages);
                setNewItemsExist(false);


            } else {
                //console.error("Failed to send array:", response.statusText);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.location.href = '/auth/login';
            }
            //console.error("Error sending array:", error);
        } finally {

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    };

    const goBack = () => {
        window.history.back(); // Equivalent to pressing the browser's back button
    };


    const handleDeleteText = (pageId, textId) => {
        const updatedPages = pages.map((page) => {
            if (page.page_id === pageId) {
                const textToDelete = page.texts.find((txt) => txt.id === textId);
                const updatedTexts = page.texts.filter((txt) => txt.id !== textId);
                setDeletedItems((prev) => [
                    ...prev,
                    { type: "text", pageId, item: textToDelete },
                ]);
                setNewItemsExist(true);
                return { ...page, texts: updatedTexts };
            }
            return page;
        });
        setPages(updatedPages);
        checkForNewItems(updatedPages);
        
    };

    const checkForNewItems = (updatedPages) => {
        const isNewItemExist = updatedPages.some(
            (page) =>
                (page.images || []).some((img) => img.id > Date.now() - 10000) ||
                (page.texts || []).some((txt) => txt.id > Date.now() - 10000)
        );
        setNewItemsExist(isNewItemExist);
    };

    const handleStoryText = (page, id, value) => {
        //console.log('page:', page)
        const storyDetails = pages.map((page) => {
            if (page.page_id === id) {
                page.title = value
            }
            return page
        })
        setPages(storyDetails)
    }
    useEffect(() => {
        //console.log('pageState:', pages)
    }, [pages])

    const handleToggleEdit = (pageId, textId, isEditing) => {
        setPages((prevPages) =>
            prevPages.map((page) =>
                page.page_id === pageId
                    ? {
                        ...page,
                        texts: page.texts.map((text) => {
                            if (text.id === textId) {
                                return {
                                    ...text,
                                    isEditing,
                                    originalText: isEditing
                                        ? text.originalText || text.text
                                        : text.originalText,
                                };
                            }
                            return text;
                        }),
                    }
                    : page
            )
        );
    };

    const handleTextChange = (pageId, textId, newText) => {
        setPages((prevPages) =>
            prevPages.map((page) =>
                page.page_id === pageId
                    ? {
                        ...page,
                        texts: page.texts.map((text) => {
                            if (text.id === textId) {
                                const isChanged = text.originalText !== newText;
                                setNewItemsExist(isChanged);
                                return { ...text, text: newText };
                            }
                            return text;
                        }),
                    }
                    : page
            )
        );
    };


    const handleDeleteImage = (pageId, imageId) => {
        const updatedPages = pages.map((page) => {
            if (page.page_id === pageId) {
                const imageToDelete = page.images.find((img) => img.id === imageId);
                const updatedImages = page.images.filter((img) => img.id !== imageId);
                setDeletedItems((prev) => [
                    ...prev,
                    { type: "image", pageId, item: imageToDelete },
                ]);
                setNewItemsExist(true)
                return { ...page, images: updatedImages };
            }
            return page;
        });

        setPages(updatedPages);
        checkForNewItems(updatedPages);        
    };

    const generatePDF = async (pages, shouldSave = true) => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [501, 614],
        });

        for (let i = 0; i < paperRefs.current.length; i++) {
            const paperRef = paperRefs.current[i];

            if (paperRef) {
                const canvas = await html2canvas(paperRef, {
                    scale: 2,
                    useCORS: true,
                    width: 501,
                    height: 614,
                    scrollX: 0,
                    scrollY: 0,
                    windowWidth: 501,
                    windowHeight: 614,
                });

                const imgData = canvas.toDataURL('image/png', 0.7);

                if (i > 0) {
                    pdf.addPage();
                }

                pdf.addImage(imgData, 'PNG', 0, 0, 501, 614);
            }
        }


        if (shouldSave) {
            pdf.save(`${book.title}.pdf`);
        }


        return pdf;
    };


    const generateWordDocument = (pages) => {
        const doc = new Document({
            creator: "Your Name", // Add creator metadata
            title: "Document Title",
            description: "Document Description",
            sections: [],
        });

        // Iterate over the pages to add content
        pages.forEach((page) => {
            const { backgroundImage, texts, images } = page;
            const sectionChildren = [];

            // Add background image if provided
            if (backgroundImage) {
                sectionChildren.push(
                    new Paragraph({
                        children: [
                            new ImageRun({
                                data: backgroundImage, // Base64 or binary image data
                                transformation: {
                                    width: 612, // Adjust based on your page width
                                    height: 792, // Adjust based on your page height
                                },
                            }),
                        ],
                    })
                );
            }

            // Add texts to the page
            texts.forEach((text) => {
                sectionChildren.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: text.content || "",
                                color: text.color || "000000",
                                bold: text.bold || false,
                                italics: text.italics || false,
                                size: text.size ? text.size * 2 : 24, // Default font size
                            }),
                        ],
                    })
                );
            });

            // Add images to the page
            images.forEach((image) => {
                sectionChildren.push(
                    new Paragraph({
                        children: [
                            new ImageRun({
                                data: image.data || "", // Base64 or binary image data
                                transformation: {
                                    width: image.width || 100, // Default width
                                    height: image.height || 100, // Default height
                                },
                            }),
                        ],
                    })
                );
            });

            // Add this section to the document
            doc.addSection({
                children: sectionChildren,
            });
        });

        // Generate and save the document as a .docx file
        Packer.toBlob(doc)
            .then((blob) => {
                saveAs(blob, "example.docx");
            })
            .catch((error) => {
                //console.error("Error generating Word document:", error);
            });
    };


    const sendPdfToBackend = async (pages) => {
        setIsSubmittingPdf(true)
        sendArrayToBackend();

        try {
            //console.log("Book object:", book);

            if (!book || !book.title || !book.book_id) {
                throw new Error('Book object is invalid or missing properties.');
            }
            const doc = await generatePDF(pages, false);
            const pdfBlob = doc.output('blob');
            //console.log("tilk", book.title);

            const formData = new FormData();
            formData.append('book', pdfBlob, `${book.title}.pdf`);
            formData.append('bookTitle', book.title);
            const pdfResponse = await axiosInstance.post('/upload/book', formData);
            const pdfUrl = pdfResponse.data.fileName;
            const bookData = {
                bookId: book.book_id,
                pdf_url: pdfUrl,

            };
            //console.log("111", bookData);

            const bookResponse = await axiosInstance.post('/create/submit/pdf', bookData);
            setSnackbarMessage("PDF submitted successfully!");
            setSnackbarOpen(true);
            //console.log(bookResponse);
            const prevpath = localStorage.getItem("preveditpath") || "/";
            const prevTab = localStorage.getItem("selectedTab") || 0;

            // Navigate to the previous path and pass prevTab in state
            navigate(prevpath, { state: { prevTab } });

            // Optionally, remove items from localStorage
            localStorage.removeItem("preveditpath");
            // localStorage.removeItem("selectedTab");


            if (isAuthenticated && user?.role === 'admin') {
                navigate('/admin');
            } else if (isAuthenticated && user?.role === 'editor') {

                navigate('/editor');
            } else {
                navigate('/user-dashboard');
            }
        } catch (error) {
            if (error.response.status === 401) {
                window.location.href = '/auth/login';
            }
            //console.error("Error generating or sending PDF:", error);
        } finally {
            setIsSubmittingPdf(false)


        }
    };

    const handleCloseAddPages = () => {
        setAnchorElAddPages(null)
    }

    const handleOpenAddPages = (e) => {
        setAnchorElAddPages(e.currentTarget)
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Delete' && selectedItemId) {
                if (itemType === 'text') {
                    handleDeleteText(currentPageId, selectedItemId);
                } else if (itemType === 'image') {
                    handleDeleteImage(currentPageId, selectedItemId);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedItemId, itemType, currentPageId]);


    useEffect(() => {
        const hasContent = pages.some(page => (page.images && page.images.length > 0) || (page.texts && page.texts.length > 0));
        setIsSubmitEnabled(hasContent);
    }, [pages]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (!e.target.closest('.draggable-image')) {
                setSelectedImageId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEditTextClick = (text) => {
        setCurrentText(text);
        setEditDialogOpen(true);
    };

    const handleDragStart = (e) => {
        // Prevent the default behavior
        e.preventDefault();
    };

    const getAlignment = (html) => {
        if (html.includes('ql-align-center')) return 'center';
        if (html.includes('ql-align-right')) return 'right';
        if (html.includes('ql-align-justify')) return 'justify';
        return 'left';  // Default to left if no alignment is specified
    };



    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
            }}
        >
            <Box
                component={"img"}
                sx={{
                    position: "fixed",
                    width: "100%",
                    top: "0px",
                    height: "100%",
                    opacity: "0.3",
                    backgroundSize: 'cover'
                }}
                src={background}
            />

            <Navbar />
            <Box
                width={"100%"}

                mt={0}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    fontWeight: "bold",
                }}
                position={"fixed"}
                top={0}
                zIndex={1}
            >
                <Grid2 container mt={10} width="100%">
                    <Grid2 item container xs={6} alignItems="center">
                        {!preview && (
                            <IconButton
                                onClick={goBack}
                                sx={{
                                    color: "black",
                                    bgcolor: "#fff",
                                    "&:hover": { bgcolor: "darkgreen", color: "#fff" },
                                    ml: 2
                                }}
                                aria-label="Go Back"
                            >
                                <ArrowBackIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        )}

                        {preview && (
                            <IconButton
                                onClick={handleOpenPageView}
                                sx={{
                                    color: "black",
                                    bgcolor: "#fff",
                                    "&:hover": { bgcolor: "darkgreen", color: "#fff" },
                                    ml: 2
                                }}
                                aria-label="Go Back"
                            >
                                <ArrowBackIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        )}

                        {!preview && (
                            <Button
                                disabled={!newItemsExist}
                                onClick={sendArrayToBackend}
                                sx={{
                                    bgcolor: "green",
                                    color: "#fff",
                                    "&:hover": { bgcolor: "darkgreen" },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    ml: 2

                                }}
                            >
                                {isLoading ? <Box width={120}><CircularProgress sx={{ color: '#ffff' }} size={15} /></Box> : 'Save Changes'}
                            </Button>
                        )}

                        {!preview && (
                            <Button
                                onClick={() => sendPdfToBackend(pages)}
                                sx={{
                                    bgcolor: "#1976d2",
                                    color: "#fff",
                                    "&:hover": { bgcolor: "#4892dc" },
                                    ml: 2
                                }}
                                disabled={!isSubmitEnabled}
                            >
                                {isSubmittingPdf ? <Box><CircularProgress sx={{ color: '#ffff' }} size={15} /></Box> : 'Submit'}
                            </Button>
                        )}
                    </Grid2>
                    <Grid2 item container xs={6} justifyContent="flex-end" alignItems="center">
                        {!preview && (
                            <Button
                                variant="contained"
                                startIcon={<LayersIcon />}
                                sx={{
                                    bgcolor: "#1976d2",
                                    color: "#fff",
                                    "&:hover": { bgcolor: "#4892dc" },
                                    mr: 2

                                }}
                                onClick={handleCapturePageView}
                            >
                                Page View
                            </Button>
                        )}

                        {!preview && (
                            <Button
                                onClick={handleOpenPageView}
                                sx={{
                                    bgcolor: "#1976d2",
                                    color: "#fff",
                                    "&:hover": { bgcolor: "#4892dc" },
                                    mr: 2
                                }}
                            >
                                Preview
                            </Button>
                        )}

                        {!preview && isAuthenticated && (user.role === "admin" || user.role === "editor") && (
                            <IconButton
                                onClick={handleOpenAddPages}
                                sx={{
                                    color: "black",
                                    bgcolor: "#fff",
                                    "&:hover": { bgcolor: "darkgreen", color: "#fff" },
                                    mr: 2
                                }}
                                aria-label="Go Back"
                            >
                                <AddIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        )}

                        {preview && isAuthenticated && (user.role === "admin" || user.role === "editor") && (
                            <>
                                <Button
                                    onClick={() => generatePDF(pages)}
                                    sx={{ bgcolor: '#1976d2', color: '#fff', '&:hover': { bgcolor: '#4892dc' }, mr: 2 }}
                                >
                                    Download PDF
                                </Button>

                                <Button
                                    // onClick={() => generateWordDocument(pages)}
                                    sx={{ bgcolor: '#1976d2', color: '#fff', '&:hover': { bgcolor: '#4892dc' }, mr: 2 }}
                                >
                                    Download Word
                                </Button>
                            </>
                        )}

                    </Grid2>
                </Grid2>
            </Box>
            {preview === false && (
                <>
                    <Box
                        component={"img"}
                        sx={{
                            position: "fixed",
                            width: { xs: "80px", sm: "500px" },
                            height: { xs: "80px", sm: "600px" },
                            left: "-72px",
                            bottom: "20px",
                        }}
                        src={book3}
                    />
                    <Box
                        component={"img"}
                        sx={{
                            position: "fixed",
                            width: { xs: "80px", sm: "500px" },
                            height: { xs: "80px", sm: "600px" },
                            right: "0",
                            bottom: "-40px",
                        }}
                        src={book2}
                    />
                </>
            )}

            {pages && pages.map((page, index) => (
                <Box
                    key={page.page_id}
                    id={`page-${page.page_id}`}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            width: '520px',
                            padding: '20px',
                            backgroundColor: '#a9ebf8',
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                            borderRadius: '10px',
                            marginBottom: '20px',
                            mt: "50px",
                            zIndex: 1
                        }}
                    >
                        {preview === false ?
                            <>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="body2" color="black" sx={{ marginRight: '8px' }}>
                                        PAGE {page.pageNumber}
                                    </Typography>

                                    <IconButton size="small" onClick={(event) => handleMenuOpen(event, page)} >
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    PaperProps={{
                                        elevation: 3,
                                        sx: {
                                            overflowy: 'hidden',
                                            position: 'absolute',
                                            filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={() => moveToTop(selectedPage)}>
                                        <ListItemIcon>
                                            <ArrowUpwardIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Move to Top</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => moveDown(selectedPage)}>
                                        <ListItemIcon>
                                            <ArrowDownwardIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Move Down</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => insertPage(selectedPage)}>
                                        <ListItemIcon>
                                            <InsertPageBreakIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Insert Page</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => deletePage(selectedPage)}>
                                        <ListItemIcon>
                                            <DeleteIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Delete Page</ListItemText>
                                    </MenuItem>
                                </Menu>

                                <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                                    {book.title}
                                </Typography>

                            </>
                            :
                            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                                <Box sx={{ color: 'black' }}>
                                    Page {page.pageNumber} of {pages.length}
                                </Box>
                            </Box>
                        }
                        <Paper
                            key={index}
                            ref={(el) => (paperRefs.current[index] = el)}
                            elevation={0}
                            sx={{
                                padding: '10px',
                                borderRadius: '8px',
                                backgroundColor: page.backgroundColor || "#ffffff",
                                backgroundImage: page.backgroundImage ? `url(${page.backgroundImage})` : 'none',
                                backgroundSize: 'cover',
                                marginBottom: '20px',
                                height: '614px',
                                width: "501px",
                                position: "relative",
                                overflow: "hidden"

                            }}
                        >
                            {(page.images || []).map((image, index) => (
                                <Rnd
                                    key={index}
                                    size={{ width: image.width, height: image.height }}
                                    position={{ x: image.position.x, y: image.position.y }}
                                    minWidth={100}
                                    minHeight={100}
                                    bounds="parent"
                                    lockAspectRatio
                                    enableResizing={!preview && isHovered ? {
                                        top: true,
                                        right: true,
                                        bottom: true,
                                        left: true,
                                        topRight: true,
                                        bottomRight: true,
                                        bottomLeft: true,
                                        topLeft: true,
                                    } : false}
                                    disableDragging={preview}
                                    onDragStart={handleDragStart}
                                    onDragStop={(e, data) => {
                                        setIsDragging(false);
                                        const newX = data.x;
                                        const newY = data.y;
                                        const updatedPages = pages.map((p) =>
                                            p.page_id === page.page_id
                                                ? {
                                                    ...p,
                                                    images: p.images.map((img) =>
                                                        img.id === image.id
                                                            ? { ...img, position: { x: newX, y: newY } }
                                                            : img
                                                    ),
                                                }
                                                : p
                                        );
                                        setPages(updatedPages);
                                    }}
                                    onResizeStop={(e, direction, ref, delta, position) => {
                                        const width = parseInt(ref.style.width, 10);
                                        const height = parseInt(ref.style.height, 10);

                                        const updatedPages = pages.map((p) =>
                                            p.page_id === page.page_id
                                                ? {
                                                    ...p,
                                                    images: p.images.map((img) =>
                                                        img.id === image.id
                                                            ? {
                                                                ...img,
                                                                width: width,
                                                                height: height,
                                                                position: {
                                                                    x: position.x,
                                                                    y: position.y,
                                                                },
                                                            }
                                                            : img
                                                    ),
                                                }
                                                : p
                                        );
                                        setPages(updatedPages);
                                    }}
                                    resizeHandleStyles={!preview ? {
                                        topRight: {
                                            background: '#8f9391',
                                            borderRadius: '50%',
                                            width: '12px',
                                            height: '12px',
                                            position: 'absolute',
                                            top: '-6px',
                                            right: '-6px',
                                            zIndex: 2,
                                        },
                                        bottomRight: {
                                            background: '#8f9391',
                                            borderRadius: '50%',
                                            width: '12px',
                                            height: '12px',
                                            position: 'absolute',
                                            bottom: '-6px',
                                            right: '-6px',
                                            zIndex: 2,
                                        },
                                        bottomLeft: {
                                            background: '#8f9391',
                                            borderRadius: '50%',
                                            width: '12px',
                                            height: '12px',
                                            position: 'absolute',
                                            bottom: '-6px',
                                            left: '-6px',
                                            zIndex: 2,
                                        },
                                        topLeft: {
                                            background: '#8f9391',
                                            borderRadius: '50%',
                                            width: '12px',
                                            height: '12px',
                                            position: 'absolute',
                                            top: '-6px',
                                            left: '-6px',
                                            zIndex: 2,
                                        },
                                    } : {}} // No resize handles if preview is true
                                    style={{
                                        border: selectedImageId === image.id ? '2px solid blue' : 'none',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        opacity: isDragging ? 0.8 : 1,
                                    }}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    className="draggable-image"
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'relative',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleImageClick(image.id);
                                            setSelectedItemId(image.id);
                                            setItemType('image');
                                            setCurrentPageId(page.page_id);
                                            setNewItemsExist(true)

                                        }}
                                    >
                                        <img
                                            src={image.src}
                                            alt="Selected"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                cursor: preview ? 'default' : 'move',
                                            }}
                                        />
                                    </div>

                                    {!preview && isHovered && (
                                        <Box
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteImage(page.page_id, image.id);
                                            }}
                                            sx={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '-28px',
                                                color: 'red',
                                                border: 'none',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <DeleteIcon />
                                        </Box>
                                    )}
                                </Rnd>
                            ))}


                            {(page.texts || []).map((text, index) => (                                
                                <Draggable
                                    key={text.id}
                                    position={text.position}
                                    onStop={(e, data) => {
                                        const updatedPages = pages.map((p) =>
                                            p.page_id === page.page_id
                                                ? {
                                                    ...p,
                                                    texts: p.texts.map((t) =>
                                                        t.id === text.id
                                                            ? { ...t, position: { x: data.x, y: data.y } }
                                                            : t
                                                    )
                                                }
                                                : p
                                        );
                                        setNewItemsExist(true);
                                        setPages(updatedPages);
                                    }}
                                    disabled={preview === true}
                                    bounds="parent"
                                >                                
                                    
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            color: text.color,
                                            fontSize: text.size,
                                            lineHeight: 1.5,
                                            display: 'inline-block',
                                            paddingRight: '24px',
                                            fontWeight: text.isBold ? 'bold' : 'normal',
                                            fontStyle: text.isItalic ? 'italic' : 'normal',  
                                            textAlign: getAlignment(text.text),                                          
                                            cursor: 'pointer',
                                            whiteSpace: 'normal', 
                                            wordBreak: 'break-word',
                                            overflowWrap: 'break-word',
                                            marginLeft: "12px",
                                            marginRight: "12px"
                                        }}                                 
                                        onClick={() => {
                                            handleTextClick(page.page_id, text.id)
                                            setSelectedItemId(text.id);
                                            setItemType('text');
                                            setCurrentPageId(page.page_id);
                                        }}
                                        onMouseEnter={() => setHoveredTextId(text.id)}
                                        onMouseLeave={() => setHoveredTextId(null)}
                                    >
                                        {text.isEditing ? (
                                            <TextField
                                                value={text.text}
                                                onChange={(e) => handleTextChange(page.page_id, text.id, e.target.value)}
                                                onBlur={() => handleToggleEdit(page.page_id, text.id, false)}
                                                autoFocus
                                                size="small"
                                                variant="outlined"
                                                multiline
                                                minRows={1}
                                                inputProps={{
                                                    maxLength: 750,
                                                }}
                                                sx={{
                                                    fontSize: text.size,
                                                    width: '400px',
                                                    '& .MuiInputBase-root': {
                                                        height: 'auto',
                                                        fontWeight: text.isBold ? 'bold' : 'normal',
                                                        fontStyle: text.isItalic ? 'italic' : 'normal',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <div dangerouslySetInnerHTML={{ __html: text.text }} />
                                        )}
                                        
                                        {preview === false && selectedTextId === text.id && hoveredTextId === text.id && (

                                            <>
                                                
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 24,
                                                        transform: 'translate(50%, -50%)',
                                                        color: 'blue',
                                                    }}
                                                    onClick={(e) => handleEditTextClick(text)}
                                                    size="small"
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        transform: 'translate(50%, -50%)',
                                                        color: 'red',
                                                    }}
                                                    onClick={() => handleDeleteText(page.page_id, text.id)}
                                                    size="small"
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>
                                </Draggable>
                            ))}
                        </Paper>

                        {preview === false &&
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '-13%',
                                    top: '45%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                {['THEMES', 'IMAGES', 'TEXT'].map((text, index) => (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        sx={{
                                            backgroundColor: index === 0 ? 'green' : index === 1 ? 'blue' : index === 2 ? 'brown' : 'gray',
                                            width: '80px',
                                            height: '40px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textTransform: 'none',
                                            color: '#fff',
                                            fontSize: '10px',
                                        }}
                                        onClick={
                                            text === 'THEMES'
                                                ? () => openScenesDialogBox(page)
                                                : text === 'IMAGES'
                                                    ? () => openItemsDialogBox(page)
                                                    : text === 'TEXT'
                                                        ? () => openTextEditorDialogBox(page)
                                                        : null
                                        }
                                    >
                                        {text}
                                    </Button>
                                ))}
                            </Box>
                        }
                    </Box>
                </Box>
            ))
            }

            {
                preview === false &&
                <>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{
                            marginTop: '20px',
                            backgroundColor: '#1976d2',
                            color: '#fff',
                            textTransform: 'none',
                            padding: '10px 20px',
                            mt: "50px",
                            mb: "50px",
                            zIndex: 2
                        }}
                        onClick={addPage}
                    >
                        Add Page
                    </Button>
                </>
            }

            {/* Scenes Dialog */}
            <ScenesDialog
                open={openScenesDialog}
                onClose={closeScenesDialogBox}
                onSelectColor={handleBackgroundColorSelect}
                onSelectImage={handleBackgroundImageSelect}
            //                 setEmpty={setEmpty}
            />
            <ItemsDialog
                open={openItemsDialog}
                onClose={closeItemsDialogBox}
                onImageSelect={handleImageSelect}
                userId={userId}
            //                 setEmpty={setEmpty}
            />

            <TextEditorDialog
                open={openTextEditorDialog}
                onClose={closeTextEditorDialogBox}
                onAddText={handleAddText}
            />

            <TextEditorDialog
                open={editDialogOpen}
                onClose={handleCloseDialog}
                initialTextData={currentText}
                handleSaveText={handleSaveText}
                isEditMode={true}
            />
            
            {/* {editDialogOpen && currentText && (
            <EditTextDialog
                open={editDialogOpen}
                handleCloseDialog={handleCloseDialog}
                text={currentText}
                handleSaveText={handleSaveText}
            />
            )} */}

            <PageViewPopup
                open={openPageView}
                onClose={handleClosePageView}
                pages={pages}
                currentPage={currentPage}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
            />

            <PageSelectorGrid
                open={openPageGridDialog}
                onClose={() => setOpenPageGridDialog(false)}
                pages={capturedPages}
                onGridClick={handlePageGridClick}
            />

            <Menu
                sx={{ marginTop: "5px" }}
                anchorEl={anchorElAddPages}
                open={Boolean(anchorElAddPages)}
                onClose={handleCloseAddPages}
            >
                <MenuItem onClick={() => addVersoContent()}>
                    Add Verso
                </MenuItem>
                <MenuItem onClick={() => addCopyrightContent()}>
                    Add Copyright
                </MenuItem>
            </Menu>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={
                        snackbarMessage.includes("Error") ? "error" : "success"
                    }
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Box >
    );
};

export default App;
