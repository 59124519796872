import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Box, Button } from '@mui/material';
import RegTerms from './RegTerms';

const TermsModal = ({ termsOpen, handleTermsClose, handleTermsAccept }) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [container, setContainer] = useState(null);
  
  const handleScroll = useCallback(() => {
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      setIsScrolledToBottom(scrollTop + clientHeight >= scrollHeight - 4);
    }
  }, [container]);

  useEffect(() => {
    if (termsOpen && container) {
      container.scrollTop = 0;
      handleScroll();
      setIsScrolledToBottom(false);
    }
  }, [termsOpen, container, handleScroll]);

  useEffect(() => {
    if (container) {
      container.addEventListener('scroll', handleScroll);
      
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [container, handleScroll]);

  return (
    <Modal open={termsOpen} onClose={handleTermsClose} aria-describedby="terms-modal-description">
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "auto", bgcolor: 'background.paper', borderRadius: "15px", boxShadow: 24, p: 4, maxWidth: '600px',}}>
        <Box ref={setContainer} sx={{ overflowY: 'auto', pr: "10px", maxHeight: '60vh', mb: 2 }}>
          <RegTerms />
        </Box>
        <Button onClick={handleTermsAccept} disabled={!isScrolledToBottom} variant="contained" color="primary" sx={{ mt: 2, color: '#ffffff', backgroundColor: "#000000", '&:hover': { backgroundColor: '#000000ff', borderColor: '#000000' },}}>
          Accept
        </Button>
      </Box>
    </Modal>
  );
};

export default TermsModal;
