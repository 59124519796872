// PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../hooks/context/AuthContext';
import Loading from "../common/Loading";


const PrivateRoute = ({ element: Element, ...rest }) => {
    const { isAuthenticated , loading} = useContext(AuthContext);

    if (loading) {
        return <Loading/>
    }

    if (!isAuthenticated) {
        return <Navigate to="/auth/login" />;
    }

    return <Element {...rest} />;
};

export default PrivateRoute;
