import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Paper, Dialog, DialogContent, DialogActions, CircularProgress, Box, TableFooter, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../utils/AxiosInstance';

const Slides = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [videoLink, setVideoLink] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [slides, setSlides] = useState([]);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dataLoad, setDataLoad] = useState(false)
    //console.log("img", slides);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null);

    // Fetch events on component mount
    useEffect(() => {
        axiosInstance.get('/slides/list')
            .then(response => {
                setSlides(response.data);
            })
            .catch(err => {
                setError(err.message);
            });
    }, []);

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
       
        // if (!title || !description || !selectedImage) {
        //     alert("All fields are required");
        //     return;
        // }
        setDataLoad(true)
        try {
            // Upload banner image
            const formDataImage = new FormData();
            formDataImage.append('banner', selectedImage);

            const imageResponse = await axiosInstance.post('/upload/event-banner', formDataImage);
            const imageUrl = imageResponse.data.fileName;
            //console.log("url", imageUrl);

            const slideData = {
                slide_title: title,
                description,
                image_url: imageUrl,
                video_url: videoLink
            };

            const slideResponse = await axiosInstance.post('/slides/create', slideData);
            //console.log('Event created successfully', eventResponse.data.event);

            if (slideResponse.data) {
                setSlides([...slides, slideResponse.data.event]);
                setDialogOpen(false);
                setSelectedImage(null);
                setTitle('');
                setDescription('');
                setVideoLink('')
            }

        } catch (error) {
            if (error.response.status === 401) {
                window.location.href = '/auth/login';
              }      
            else if (error.response && error.response.data) {
                alert(error.response.data.message);
            } else {
                //console.error('Error creating the event:', error);
            }
        } finally {
            setDataLoad(false)
        }
    };


    const handleDeleteClick = async (slideId) => {
        
            try {
                await axiosInstance.delete(`/slides/delete/${slideId}`);

                setSlides(slides.filter(slide => slide.id !== slideId));

                //console.log('Event deleted successfully');
            } catch (error) {
                if (error.response.status === 401) {
                    window.location.href = '/auth/login';
                  }      
                //console.error('Error deleting event:', error);
                alert('Failed to delete event');
            }finally{
                setOpenDeleteDialog(false)
            }
        
    };
    
    const handleOpenDeleteUser=(id)=>{
        setSelectedUser(id)
        setOpenDeleteDialog(true)
      }
      const handleCloseDeleteDialog=()=>{
        setOpenDeleteDialog(false)
        
      }


    // Handle file change
    const handleFileChange = (slide) => {
        const file = slide.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    // Handle dialog open
    const handleEventOpen = () => {
        setDialogOpen(true);
    };

    // Handle dialog close
    const handleDialogClose = () => {
        setDialogOpen(false);
        setTitle("");
        setDescription("");
        setSelectedImage(null);
    };

    const handleDeleteImage = () => {
        setSelectedImage(null); // Remove the selected image from the state
        //console.log('Image has been deleted.');
    };

    return (
        <Grid container direction="column" spacing={2} sx={{ padding: 2 }}>
            <Grid item container justifyContent="space-between">
                <Typography variant='h3' fontWeight="bold">Slides</Typography>
                <Button onClick={handleEventOpen} variant='contained' size='medium'>Add Slides</Button>
            </Grid>

            {slides.length > 0 && (
                <Grid item xs={12} mb="30px">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow  sx={{ backgroundColor: "#1976d2" }}>
                                    <TableCell sx={{ color: "#fff" }}>S.No</TableCell>
                                    <TableCell sx={{ color: "#fff" }}>Title</TableCell>
                                    <TableCell sx={{ color: "#fff" }}>Description</TableCell>
                                    <TableCell sx={{ color: "#fff" }}>Image</TableCell>
                                    <TableCell sx={{ color: "#fff" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {slides.map((slide, index) => (
                                    <TableRow key={slide.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{slide.slide_title}</TableCell>
                                        <TableCell>{slide.description}</TableCell>
                                        <TableCell>
                                            <img
                                                src={`${process.env.REACT_APP_EVENT_BANNER_URL}${slide.image_url}`}
                                                alt="slide Banner"
                                                style={{ width: '100px', height: '100px' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleOpenDeleteUser(slide.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}

            {/* Add Event Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogContent>
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                        <Typography variant='h5' fontWeight="bold" textAlign="center" p="20px">Add Slides</Typography>

                        <Grid item container spacing={2} justifyContent="center" alignItems="center">
                            <TextField
                                label="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                sx={{ width: '80%', mb: "20px" }}
                            />
                            <TextField
                                label="Description"
                                multiline
                                rows={4}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{ width: '80%', mb: "20px" }}
                            />
                            <TextField
                                label="Video Link"
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                                sx={{ width: '80%' }}
                            />
                        </Grid>

                        {selectedImage && (
                            <Grid item style={{ position: 'relative' }}>
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="Selected Preview"
                                    style={{ width: '150px', height: '150px', marginLeft: '10px' }}
                                />
                                <IconButton
                                    onClick={handleDeleteImage}
                                    aria-label="delete"
                                    color="error"
                                    style={{ position: 'absolute', top: 0, right: 0 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        )}

                        <Grid item>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="upload-image"
                            />
                            <label htmlFor="upload-image">
                                <IconButton disableRipple color="primary" component="span">
                                    <CloudUploadIcon sx={{ fontSize: 40 }} />
                                    <Typography pl="2px">Upload Image</Typography>
                                </IconButton>
                            </label>
                        </Grid>
                       

                    </Grid>
                </DialogContent>
                <DialogActions>
                <TableFooter>
                            <Grid item>
                                <Button  color="primary" onClick={handleSubmit}>
                                    {dataLoad ? <Box width={60}><CircularProgress size={15} sx={{ color: "secondary" }} /></Box> : 'Submit'}
                                </Button>
                            </Grid>
                        </TableFooter>
                    <Button onClick={handleDialogClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
           open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this Slide?"}
          </DialogTitle>
          <DialogActions>
            <Button color="primary" 
            onClick={handleCloseDeleteDialog}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteClick(selectedUser)}
              color="secondary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        </Grid>
    );
};

export default Slides;
