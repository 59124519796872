import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PageViewPopup = ({ open, onClose, pages, currentPage, handlePrevPage, handleNextPage }) => {
    if (!open) return null;
     const sendArrayToBackend = async () => {
        try {
            const response = await fetch('https://your-backend-endpoint/api/array', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ arrayData: pages }),
            });

            if (response.ok) {
                const data = await response.json();
                //console.log('Array sent successfully:', data);
            } else {
                //console.error('Failed to send array:', response.statusText);
            }
        } catch (error) {
            //console.error('Error sending array:', error);
        }
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Glass black background
                backdropFilter: 'blur(5px)', // Optional: blur effect on the background
                zIndex: 1300, // To ensure it appears above other content
            }}
            onClick={onClose}
        >
            <Box
                sx={{
                    position: 'relative',
                    // backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '8px',
                    padding: '20px',
                    width: '450px',
                    height: '600px',
                    overflow: 'hidden',
                    // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={(e) => e.stopPropagation()} 
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <IconButton onClick={handlePrevPage} disabled={currentPage === 0}>
                        <ArrowBackIosIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <Button sx={{ bgcolor: '#1976d2', color: '#fff', '&:hover': { bgcolor: '#4892dc' } }} >Submit</Button>
                    <Box sx={{ color: 'white' }}>
                        Page {currentPage + 1} of {pages.length}
                    </Box>
                    <IconButton onClick={handleNextPage} disabled={currentPage === pages.length - 1}>
                        <ArrowForwardIosIcon sx={{ color: 'white' }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        padding: '10px',
                        borderRadius: '8px',
                        backgroundColor: pages[currentPage].backgroundColor || "#b8e1f3",
                        backgroundImage: pages[currentPage].backgroundImage ? `url(${pages[currentPage].backgroundImage})` : 'none',
                        backgroundSize: 'cover',
                        height: '100%',
                        width: '100%',
                        overflowY: 'auto',
                    }}
                >
                    {pages[currentPage].texts && pages[currentPage].texts.map((text, index) => (
                        <Box
                            key={index}
                            sx={{
                                color: text.color,
                                fontSize: text.size,
                                marginBottom: '10px',
                            }}
                        >
                            {text.text}
                        </Box>
                    ))}
                    {pages[currentPage].selectedImage && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <img
                                src={pages[currentPage].selectedImage}
                                alt="Selected"
                                style={{
                                    width: '200px',
                                    height: '200px',
                                    objectFit: 'contain',
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box>
            
            </Box>
        </Box>
    );
};

export default PageViewPopup;
