import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import axiosInstance from "../utils/AxiosInstance";
 
const Paymentpopup = () => {
  useEffect(() => {
    // Check if PayPal script is already added
    if (!document.querySelector('#paypal-donate-sdk')) {
      // Load the PayPal Donate SDK script
      const script = document.createElement('script');
      script.src = 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js';
      script.async = true;
      script.id = 'paypal-donate-sdk';
      script.onload = () => {
        if (window.PayPal) {
          // Initialize the PayPal Donate button
          window.PayPal.Donation.Button({
            env: 'production', // Change to 'production' in a live environment
            hosted_button_id: 'LDMLJEG5H2ZCN', // Replace with your PayPal hosted button ID
            image: {
              src: 'https://pics.paypal.com/00/s/Y2E2MTQ2NDQtNjMxNy00MTA0LWFjMGUtNzdiYjY0N2VkODJk/file.JPG',
              alt: 'Donate with PayPal button',
              title: 'PayPal - The safer, easier way to pay online!',
            },
            onApprove: function (data,actions) {
              // Called when the donation is approved
              //console.log('Donation approved', data);

               // Capture the donation details
            actions.order.capture().then(function(details) {
              const payer = details.payer;
              const payerEmail = payer.email_address;
              const payerName = `${payer.name.given_name} ${payer.name.surname}`;

              // Log the payer's info
              //console.log('Payer Email:', payerEmail);
              //console.log('Payer Name:', payerName);

              // Save donation details with payer info
              saveDonationDetails(details,data, 'success', payerEmail, payerName);
            });
            },
            onCancel: function (data) {
              // Called when the donation is canceled
              //console.log('Donation canceled', data);
              saveDonationDetails(data, 'canceled');
            },
          }).render('#donate-button'); // Render PayPal button into this div
        }
      };
      document.body.appendChild(script);
    }
 
    // Cleanup function to remove the button
    return () => {
      const buttonContainer = document.getElementById('donate-button');
      if (buttonContainer) {
        buttonContainer.innerHTML = '';
      }
    };
  }, []);
 
  // Function to save donation details
  // Function to save donation details
const saveDonationDetails = async (data, status, payerEmail = null, payerName = null) => {
  try {
    const response = await axiosInstance.post('/payment/webhook', {
      resource: {
        id: data.orderID,
        amount: { value: data.amount, currency_code: data.currency },
        payer: {
          email: payerEmail,
          name: payerName,
        },
      },
      event_type: status === 'success' ? 'PAYMENT.CAPTURE.COMPLETED' : 'PAYMENT.CAPTURE.DENIED',
    });

    if (response.status === 200) {
      //console.log('Donation saved successfully');
    } else {
      //console.error('Error saving donation');
    }
  } catch (error) {
    //console.error('Error saving donation details:', error);
  }
};
  // const saveDonationDetails = async (data, status) => {
  //   try {
  //     const response = await axiosInstance.post('/payment/webhook', {
  //       resource: {
  //         id: data.orderID,
  //         amount: { value: data.amount, currency_code: data.currency },
  //       },
  //       event_type: status === 'success' ? 'PAYMENT.CAPTURE.COMPLETED' : 'PAYMENT.CAPTURE.DENIED',
  //     });
 
  //     if (response.status === 200) {
  //       //console.log('Donation saved successfully');
  //     } else {
  //       //console.error('Error saving donation');
  //     }
  //   } catch (error) {
  //     //console.error('Error saving donation details:', error);
  //   }
  // };
 
 
  return (
    <Container>
      <Box sx={{display:'flex', justifyContent:'center'}}>
        {/* <Typography variant="h3" color="textSecondary">
          Donate to Story Buzz
        </Typography> */}
        {/* <Typography sx={{ my: 2 }}>
          Help us continue our mission and{' '}
          <Link
            href="https://www.sandbox.paypal.com/donate/?hosted_button_id=79FZ7MSHZZTVN"
            target="_blank"
          >
            donate to Story Buzz.
          </Link>
        </Typography> */}
 
        <Box id="donate-button" sx={{ mt: 4}}></Box>
      </Box>
    </Container>
  );
};
 
export default Paymentpopup;