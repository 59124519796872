import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../utils/AxiosInstance";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const users = [
  {
    id: 1,
    username: "John Doe",
    profilePic: "/path/to/profile1.jpg",
    status: "active",
  },
  {
    id: 2,
    username: "Jane Smith",
    profilePic: "/path/to/profile2.jpg",
    status: "inactive",
  },
  {
    id: 3,
    username: "Alice Johnson",
    profilePic: "/path/to/profile3.jpg",
    status: "active",
  },
];

const UserProfile = () => {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profileUpdate, setProfile] = useState([]);
  const [profilePictures, setProfilePictures] = useState([]);

  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDialogOpen = (id, action) => {
    setSelectedProfileId(id);
    setActionType(action);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedProfileId(null);
    setActionType("");
  };

  const handleDialogConfirm = async () => {
    if (actionType === "approve") {
      await handleApprove(selectedProfileId);
    } else if (actionType === "reject") {
      await handleReject(selectedProfileId);
    }
    // Clear the table data after confirmation
    setProfile([]); // Assuming setProfileUpdate is the function to update the table data state
    handleDialogClose();
  };

  const handleProfileClick = (profilePic) => {
    setSelectedProfile(profilePic);
  };

  const handleClose = () => {
    setSelectedProfile(null);
  };

  // const handleApprove = (id) => {
  //   //console.log(`Approved user with id: ${id}`);
  //   // Add your approve logic here
  // };

  // const handleReject = (id) => {
  //   //console.log(`Rejected user with id: ${id}`);
  //   // Add your reject logic here
  // };

  // Inside your React component
  useEffect(() => {
    fetchProfilePictures();
  }, []);

  const fetchProfilePictures = async () => {
    try {
      const response = await axiosInstance.get(
        "/users/submittedProfilepicture"
      );
      setProfile(response.data);
      // Assuming response.data is an array of user objects
      const profilePictures = response.data.map((user) => user.profile_picture);

      // Set the profile pictures in the state
      setProfilePictures(profilePictures);

      //console.log("Profile pictures:", profilePictures);
    } catch (error) {
      //console.error("Error fetching profile pictures:", error);
    }
  };

  const handleApprove = async (id) => {
    try {
      const response = await axiosInstance.put(
        `/users/profile-approval/${id}`,
        {
          status: "approved",
        }
      );

      if (response.status === 200) {
        // Update your state to reflect the change if needed
        fetchProfilePictures();
        showSnackbar("Profile has been successfully approved.", "success");
      }
    } catch (error) {
      //console.error("Error approving profile:", error);
      showSnackbar("An error occurred while approving the profile.", "error");
    }
  };

  const handleReject = async (id) => {
    //console.log(id);

    try {
      const response = await axiosInstance.put(
        `/users/profile-approval/${id}`,
        {
          status: "rejected",
        }
      );

      if (response.status === 200) {
        // Update your state to reflect the change if needed
        //console.log("Profile rejected:", response.data.user);
        fetchProfilePictures();
        showSnackbar("Profile has been successfully rejected.", "error");
      }
    } catch (error) {
      //console.error("Error rejecting profile:", error);
      showSnackbar("An error occurred while rejecting the profile.", "error");
    }
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1976d2" }}>
              <TableCell sx={{ color: "#fff" }}>S.No</TableCell>
              <TableCell sx={{ color: "#fff" }}>User</TableCell>
              <TableCell sx={{ color: "#fff" }}>Profile</TableCell>
              <TableCell sx={{ color: "#fff" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profileUpdate.map((profiles, index) => (
              <TableRow key={profiles.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{profiles.first_name}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleProfileClick(profiles.profile_picture)}
                  >
                    <Avatar
                      alt={profiles.first_name}
                      src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/user/profile_pic/${profiles.profile_picture}`}
                    />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {profiles.profile_approval_status === "approved" ? (
                    <span style={{ color: "green" }}>Approved</span>
                  ) : profiles.profile_approval_status === "rejected" ? (
                    <span
                      style={{
                        color: "red",
                        "&:hover": {
                          backgroundColor: "#fd4444",
                        },
                      }}
                    >
                      Rejected
                    </span>
                  ) : (
                    <div>
                      <IconButton
                        onClick={() =>
                          handleDialogOpen(profiles.user_id, "approve")
                        }
                        sx={{
                          color: "white",
                          backgroundColor: "green",
                          borderRadius: "50%",
                          padding: "8px",
                          marginRight: "8px",
                          "&:hover": {
                            backgroundColor: "#45bf45",
                          },
                        }}
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDialogOpen(profiles.user_id, "reject")
                        }
                        sx={{
                          color: "white",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          padding: "8px",
                          "&:hover": {
                            backgroundColor: "#ff4a2a",
                          },
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </IconButton>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={Boolean(selectedProfile)} onClose={handleClose}>
        <DialogTitle>Profile Picture</DialogTitle>
        <DialogContent>
          <Avatar
            alt="Selected Profile"
            src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/user/profile_pic/${profilePictures}`}
            sx={{ width: "200px", height: "200px" }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>
          {actionType === "approve" ? "Approve Profile" : "Reject Profile"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {actionType === "approve"
              ? "Are you sure you want to approve this profile?"
              : "Are you sure you want to reject this profile?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogConfirm} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default UserProfile;
