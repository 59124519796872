import React from 'react';
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, FormControl, RadioGroup, Card, CardContent, Radio, FormControlLabel, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Address = ({
  expanded,
  handleChange,
  usedAddress,
  handleCloseClick,
  addresses,
  selectedAddress,  
  handleAddressChange,
  handleEditAddress,
  handleOpenDialog,
  handleUseAddress,
  
}) => {    
    
  return (
    <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ border: "none", boxShadow: "none" }}
            >
              <AccordionSummary>
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      1. Shipping Address
                    </Typography>
                  </Grid>

                  <Grid item        sx={{
                          width: "150px",
                          display: "flex",
                          alignItems: "center",
                          height: "auto",
                        }}>
                    {expanded !== "panel1" && usedAddress && (
                      <Box>
                         <Typography variant="subtitle1">
                                  {usedAddress.name}
                                </Typography>
                                <Typography variant="body2">
                                  
                                {usedAddress.address_line_2}
                                </Typography>
                                <Typography variant="body2"> {usedAddress.address_line_1}.</Typography>
                                <Typography>{usedAddress.city}, {usedAddress.state}, {usedAddress.zipcode}.                                 
                                </Typography>        
                      </Box>
                    )}
                  </Grid>

                  <Grid item>
                    {expanded === "panel1" ? (<></>
                    ) : (
                      <Button
                        variant="text"
                        sx={{
                          color: "#FF4E33",
                          marginLeft: "auto",
                          height: "48px",
                        }}
                        onClick={handleChange("panel1")}
                      >
                        Change
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedAddress ? selectedAddress.id : ""}
                    onChange={handleAddressChange}
                    aria-label="shipping address"
                    name="shipping-address"
                  >
                    {addresses.map((address) => (
                      <Card
                        key={address.id}
                        sx={{
                          marginBottom: 2,
                          border: "none",
                          boxShadow: "none",
                        }}
                      >
                        <CardContent>
                          <FormControlLabel
                            value={address.id}
                            control={
                              <Radio
                                sx={{
                                  color: "#FF4E33",
                                  "&.Mui-checked": { color: "#FF4E33" },
                                }}
                              />
                            }
                            label={
                              <Box>
                                <Typography variant="subtitle1">
                                  {address.name}
                                </Typography>
                                <Typography variant="body2">
                                  
                                {address.address_line_2}, {address.address_line_1},
                                </Typography>
                                <Typography>{address.city}, {address.state}, {address.zipcode}.
                                 
                                </Typography>
                                <Typography>{address.email_id}.</Typography>
                                <Typography> {address.mobile_number}.</Typography>
                                <Typography
                                  variant="body"
                                  fontSize={14}
                                  color="#FF4E33"
                                  sx={{
                                    "&:hover": { textDecoration: "underline" },
                                  }}
                                  //  onClick={handleEditDialog}
                                  //  onClick={handleEditAddress}
                                  onClick={() => handleEditAddress(address.id)}
                                >
                                  Edit address
                                </Typography>
                              </Box>
                            }
                          />
                        </CardContent>
                      </Card>
                    ))}
                  </RadioGroup>

                </FormControl>
                <Grid item xs={12} >
                  <Box
                    display="flex"
                    justifyContent="center"
                    onClick={handleOpenDialog}
                  >
                    <AddIcon />
                    <Typography
                      sx={{
                        "&:hover": { textDecoration: "underline" },
                        marginBottom: 2,
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      Add a new address
                    </Typography>

                  </Box>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      variant="contained"
                      sx={{
                        height: "30px",
                        width: "180px",
                        backgroundColor: "#FF4E33",
                        color: "white",
                        "&:hover": { backgroundColor: "#FF8C00" },
                        borderRadius: "20px",
                      }}
                      onClick={() => handleUseAddress("panel2")}
                      disabled={addresses.length === 0}
                    >
                      Use this address
                    </Button>
                  </Box>

                </Grid>

              </AccordionDetails>
            </Accordion>
  );
};

export default Address;
