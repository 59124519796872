import React from 'react';
import { Box, Typography, Card, Button } from '@mui/material';

const OrderSummary = ({
  totalPrice=0,
  discount=0,
  shippingCharge=0,
  taxAmount=0,
  totalPriceWithTax=0,
  onPlaceOrder,
  showButton = true,
  buttonText = "Proceed to checkout",
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Card sx={{ p: 2, position: "sticky", top: 20, border: "1px solid #B6B3B2" }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", textAlign: 'center' }}>
          Order Summary
        </Typography>
        <hr style={{ border: "1px solid #B6B3B2", width: "100%" }} />

        <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
          <Typography>Total:</Typography>
          <Typography>${totalPrice}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography>Discount:</Typography>
          <Typography color="error">- ${discount}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography>Shipping Charges:</Typography>
          <Typography color="success">${shippingCharge.toFixed(2)}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography>Tax (7%):</Typography>
          <Typography color="success">${taxAmount.toFixed(2)}</Typography>
        </Box>

        <hr style={{ border: "1px solid #B6B3B2", width: "100%" }} />

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="h6" fontWeight="bold">
            Order Total:
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            ${totalPriceWithTax}
          </Typography>
        </Box>
        
        {showButton && (
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#FF4E33",
              color: "white",
              "&:hover": { backgroundColor: "#FF8C00" },
              mt: 2,
            }}
            onClick={onPlaceOrder}
          >
            {buttonText}
          </Button>
        )}
      </Card>
    </Box>
  );
};

export default OrderSummary;
