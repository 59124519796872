import { Box, CircularProgress } from '@mui/material'
import React from 'react'

function PurchaseLoading() {
  return (
    <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.5)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 1,}}>
        <CircularProgress style={{ color: "#FF4E33" }} />
    </Box>
  )
}

export default PurchaseLoading;

