import React from "react";
import { Typography, Box } from "@mui/material";
import Done from '@mui/icons-material/Done';
import NotMatchIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const PasswordValidation = ({ validations, validateErr }) => {
    const {t} = useTranslation("login")
return (
    <Box>
        {validateErr && (
            <Typography color="error">{validateErr}</Typography>
        )}
        <Box display="flex" alignItems="center">
            {validations.hasLowercase ? <Done color="success"/> : <NotMatchIcon color="error" fontSize="small"/>}
            <Typography fontSize="small">{t("register.onelowercase")}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
            {validations.hasUppercase ? <Done color="success" /> : <NotMatchIcon color="error" fontSize="small"/>}
            <Typography fontSize="small">{t("register.oneuppercase")}</Typography>
        </Box>
        
        <Box display="flex" alignItems="center">
            {validations.hasNumber ? <Done color="success" /> : <NotMatchIcon color="error" fontSize="small"/>}
            <Typography fontSize="small">{t("register.onenumber")}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
            {validations.hasSpecialChar ? <Done color="success" /> : <NotMatchIcon color="error" fontSize="small"/>}
            <Typography fontSize="small">{t("register.splchar")}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
            {validations.isLongEnough ? <Done color="success" /> : <NotMatchIcon color="error" fontSize="small"/>}
            <Typography fontSize="small">{t("register.beatleast8characters")}</Typography>
        </Box>
    </Box>
);
};

export default PasswordValidation;
