import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Box, Grid, Button } from "@mui/material";
import donateImage from "../../assets/donate.jpg";
import logonew from "../../assets/logonew.png";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import axiosInstance from "../utils/AxiosInstance";

const DonationReject = () => {
        useEffect(() => {          
          if (!document.querySelector('#paypal-donate-sdk')) {            
            const script = document.createElement('script');
            script.src = 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js';
            script.async = true;
            script.id = 'paypal-donate-sdk';
            script.onload = () => {
              if (window.PayPal) {                
                window.PayPal.Donation.Button({
                  env: 'production',
                  hosted_button_id: '228X35YUN4CAG',                                   
                  onApprove: function (data) {
                    // Called when the donation is approved
                    //console.log('Donation approved', data);
                    saveDonationDetails(data, 'success');
                  },
                  onCancel: function (data) {
                    // Called when the donation is canceled
                    //console.log('Donation canceled', data);
                    saveDonationDetails(data, 'canceled');
                  },
                }).render('#donate-button'); // Render PayPal button into this div
              }
            };
            document.body.appendChild(script);
          }
       
          // Cleanup function to remove the button
          return () => {
            const buttonContainer = document.getElementById('donate-button');
            if (buttonContainer) {
              buttonContainer.innerHTML = '';
            }
          };
        }, []);
       
        // Function to save donation details
        const saveDonationDetails = async (data, status) => {
          try {
            const response = await axiosInstance.post('/payment/webhook', {
              resource: {
                id: data.orderID,
                amount: { value: data.amount, currency_code: data.currency },
              },
              event_type: status === 'success' ? 'PAYMENT.CAPTURE.COMPLETED' : 'PAYMENT.CAPTURE.DENIED',
            });
       
            if (response.status === 200) {
              //console.log('Donation saved successfully');
            } else {
              //console.error('Error saving donation');
            }
          } catch (error) {
            //console.error('Error saving donation details:', error);
          }
        };

    return (
        <Container maxWidth="md" sx={{ marginTop: "50px" }}>
            <Grid container spacing={2} alignItems="center">
                {/* Left side: Image */}
                <Grid item xs={12} md={6}>
                    <img
                        src={donateImage}
                        alt="Donate"
                        style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                        }}
                    />
                </Grid>

                {/* Right side: Content */}
                <Grid item xs={12} md={6}>
                    <Box textAlign="left">
                        <Grid sx={{ alignItems: "center", marginLeft: "180px", marginBottom: "20px" }}>
                            <Box
                                component="img"
                                src={logonew}
                                sx={{ width: "50px", height: "50px", cursor: "pointer" }}
                            />
                        </Grid>
                        {/* <Box sx={{ textAlign: "center" }}>
              {" "}
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Thank You For Your Generous Donation
              </Typography>
            </Box> */}

                        <Box sx={{ textAlign: "center", marginRight: "250px" }}>
                            <Typography variant="body1" gutterBottom sx={{ fontWeight: "bold" }}>
                                Still want to donate?
                            </Typography>
                        </Box>


                        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                            <Link style={{ textDecoration: "none", color: "inherit" }}>
                                Help us continue our mission and
                                <span style={{ textDecoration: "underline", marginLeft: "5px", color: "blue" }}>
                                    Donate to StoryBuzz today.
                                </span>
                            </Link>
                        </Box>

                        <Box sx={{ textAlign: "center", marginTop: "20px", marginLeft: "100px" }}>
                            <Box id="donate-button" sx={{ my: 4 }}></Box>
                        </Box>


                        <Box sx={{ textAlign: "center", marginRight: "250px", marginTop:"30px" }}>
                            <Typography variant="body1" gutterBottom>
                               Changed your mind?
                            </Typography>
                        </Box>

                        <Box sx={{marginTop: "10px", marginLeft:"13px"}}>
                            <Link
                                to="/"
                                style={{ textDecoration: "underline", color: "blue" }}
                            >
                                Return to the home page
                            </Link>
                        </Box>

                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default DonationReject;
