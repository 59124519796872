import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Box, Grid } from "@mui/material";
import donateImage from "../../assets/donate.jpg";
import logonew from "../../assets/logonew.png"; 

const DonationSuccess = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: "50px" }}>
      <Grid container spacing={2} alignItems="center">
        {/* Left side: Image */}
        <Grid item xs={12} md={6}>
          <img
            src={donateImage}
            alt="Donate"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
            }}
          />
        </Grid>

        {/* Right side: Content */}
        <Grid item xs={12} md={6}>
          <Box textAlign="left">
            <Grid sx={{ alignItems: "center", marginLeft:"180px" }}>
              <Box
                component="img"
                src={logonew}
                sx={{ width: "50px", height: "50px", cursor: "pointer" }}
              />
            </Grid>
            <Box sx={{ textAlign: "center" }}>
              {" "}
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Thank You For Your Generous Donation
              </Typography>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              {" "}
              <Typography variant="body1" gutterBottom>
                You are awesome! Your contribution will help us greatly.
              </Typography>
            </Box>

            <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <Link
                to="/"
                style={{ textDecoration: "underline", color: "blue" }}
              >
                Return to the home page
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DonationSuccess;
