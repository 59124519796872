import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18 from "./i18n/i18n"; 
import { GlobalStyles } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
      <GlobalStyles
        styles={{
          '.MuiTouchRipple-child': {
            background: 'none !important',
          },
        }}
      />
  </React.StrictMode>
  
);