import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Grid, Divider } from '@mui/material';
import logonew from '../../assets/logonew.png'

const Terms = () => {
  return (
    <Box >
    <Box
      sx={{
        position: 'relative',
        height: 'auto',
        width: '100%',
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          height: 'auto',
          width: 'auto',
          // backgroundImage: `url(${logonew})`,
        //   backgroundColor:'#1ab5f8f6',
          backgroundRepeat: 'repeat-y',
          backgroundSize: 'auto',
          backgroundPosition: 'center',
          opacity: 0.1,
          zIndex: -1,
        }}
      />
      <Grid container direction="column" >
        <Grid item>
          <Typography variant="h4" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            Terms and Conditions
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "10px", marginLeft: "30px" }}>Welcome to Storybuzz.org, a website dedicated to providing quality children's books. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' sx={{ widht: 'auto', maginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>1. Acceptance of Terms</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ widht: "auto", marginTop: "10px", marginLeft: "50px" }}>By accessing this website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree to these terms, please do not use our website.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' sx={{ widht: 'auto', marginTop: "10px", marginLeft: '30px', fontWeight: "bold" }}>Our Role and Responsibilities</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ widht: "auto", marginTop: '10px', marginLeft: '30px', }}>You understand and agree that:</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ widht: "auto", marginTop: '5px', marginLeft: '50px' }}>i. We provide a platform for communication among users and visitors of the website. We act as an intermediary facilitating the creation, adaptation, sharing, and exchange of children's books, stories, and information.<br />

            ii. The website may contain user-generated content and materials. We do not initiate or modify transmissions and have no control over third-party user-generated content.<br />

            iii. While promoting our platform on social media, inadvertent data collection may occur via codes used for targeted advertising campaigns. We do not access such user data and cannot guarantee non-occurrence of such campaigns initiated by social media platforms like Facebook, Instagram, YouTube.<br />

            iv. We do not guarantee or mediate user activities or resolve disputes between users or visitors.<br />

            v. We do not verify the attributes (legal title, creditworthiness, identity, etc.) of users or visitors. It is your responsibility to independently verify any user or visitor and their information.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' sx={{ widht: "auto", marginTop: "15px", marginLeft: "30px", fontWeight: "bold" }}>2. Eligibility</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: '30px' }}>Eligibility Criteria for Website Use by Children</Typography>
        </Grid>
        <Grid item>
          <Typography varient='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "50px" }}><b> . Age Requirement:</b> Children under the age of 18 may use this website only with the involvement and supervision of a parent or guardian.<br />

            <b> · Parental Consent: </b>Parents or guardians must provide explicit consent for their children to access and use the website. This consent includes agreeing to the website's terms of use and privacy policy.<br />

            <b> · Supervision:</b> Parents or guardians are required to actively supervise their children's use of the website. This includes monitoring the content accessed and ensuring that it is appropriate for the child's age and maturity level.<br />

            <b> · Account Creation:</b> If the website requires account creation, children under 18 must have their parent or guardian create and manage the account. All information provided during account creation must be accurate and truthful.<br />

            <b>· Behavioral Guidelines:</b>  Children using the website must adhere to the site's behavioral guidelines. This includes respectful interaction with other users and compliance with community standards and rules.<br />

            <b>· Privacy and Safety:</b>  The website takes the privacy and safety of its users seriously. Parents and guardians should educate their children about online safety practices and ensure that personal information is not shared without consent.<br />

            <b> · Reporting Concerns:</b> Parents or guardians should promptly report any concerns or inappropriate content encountered on the website to the website administrators for immediate action.<br />

            · By adhering to these criteria, parents and guardians can ensure a safe and enjoyable online experience for their children</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h5' sx={{ width: "auto", marginTop: '15px', marginLeft: '30px', fontWeight: "bold" }}>3. User Accounts and Registration</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: '10px', marginLeft: "50px" }}>To access certain features of the website, you may be required to create an account. You agree to provide accurate and complete information and to update your information as necessary. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account.<br /><br />

            If you use the Website as a Registered User, you are responsible for maintaining the confidentiality of your User ID and Password. You are responsible for all activities that occur under your User ID and Password. You agree, among other things, to provide true, accurate, current, and complete information about yourself as prompted by the Website registration form or provided by you as a Visitor or user of a third-party site through which you access the Website. If you provide any information that is untrue, inappropriate, inaccurate, not current, or incomplete, or if Tamilezhuthapadi Publications or Storybuzz.org has reasonable grounds to suspect that such information is untrue, inaccurate, inappropriate, not current, or incomplete, or not in accordance with the User Agreement, Tamilezhuthapadi Publications or Storybuzz.org has the right to indefinitely suspend, terminate, or block access to your membership with the Website and refuse to provide you with access to the Website.<br /><br />

            You may not transfer your Account to anyone else without our prior written permission.<br /><br />

            If you are agreeing to these Terms of Use on behalf of an organization or entity (for example, if you’re an administrator agreeing to these Terms of Use on behalf of your school/organization/district), you represent and warrant that you are authorized to agree to these Terms of Use on that organization or entity's behalf and bind them to these Terms of Use.<br /><br />

            You hereby grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, and database rights you have in your information, in any media or medium now known or developed, produced, invented, or used in the future, with respect to your information.</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" sx={{ widht: "auto", marginTop: '15px', marginLeft: '30px', fontWeight: "bold" }}>4. Use of Content</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ widht: "auto", marginTop: '10px', marginLeft: "50px" }}>All content provided on this website, including text, graphics, images, and audio, is for your personal and non-commercial use only. You may not modify, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from this website without our prior written consent.<br /><br />

            Anything you post, upload, share, store, or otherwise provide through the Services is your "User Submission." Almost all User Submissions are viewable by other users. To display your User Submissions on the Services and allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions. Please note that all of the following licenses are subject to our Privacy Policy to the extent they relate to User Submissions that are also your personally identifiable information.<br /><br />

            For all User Submissions, you hereby grant Tamilezhuthapadi Publications or Storybuzz.org a license to translate, modify (for technical purposes, for example making sure your content is viewable on different devices), and reproduce such User Submissions, in each case to enable us to operate the Services, as described in more detail below. This is a license only; your ownership in User Submissions is not affected.<br /><br />

            You agree that the licenses you grant are royalty-free, irrevocable, and worldwide, for as long as your User Submissions are stored with us. You understand and agree that it is not possible to delete your account and your User Submissions may remain viewable elsewhere to the extent they were copied or stored by other users. You also agree that we are required under applicable laws to store (even if not publicly display or distribute) the Content, User Submissions, and your other information or data for a certain time and possibly forever if any such information or data is subject to law enforcement or judicial actions.<br /><br />

            Finally, you understand and agree that Tamilezhuthapadi Publications or Storybuzz.org, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>A. Takedown Policy</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>We may at our sole and absolute discretion delete, remove, disable access to, or otherwise deal with your information and data on the website or in the computer resource of Tamilezhuthapadi Publications or Storybuzz.org to comply with such laws.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>B. All Information</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>This policy applies to all content published on Tamilezhuthapadi website, including but not limited to text, images, videos, and other media.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>C. Grounds for Takedown Requests</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>a. Takedown requests may be submitted for the following reasons:<br />

            b. Copyright infringement<br />

            c. Trademark infringement<br />

            d. Privacy violations<br />

            e. Defamation or libel<br />

            f. Hate speech or harassment<br />

            g. Any other content that violates local laws or our Terms of Service.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>D. Submission of Takedown Requests</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>a. To submit a takedown request, please provide the following information:<br />

            b. Your name and contact information<br />

            c. A description of the content you wish to be removed<br />

            d. The URL or location of the content<br />

            e. The reason for the takedown request<br />

            f. Any supporting evidence or documentation<br />

            g. Submit requests to: [Contact Information]</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>E. Review Process</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>a. Upon receiving a takedown request, we will:<br />

            b. Acknowledge receipt of the request<br />

            c. Review the request and the content in question<br />

            d. Contact the content owner if necessary<br />

            e. Make a decision regarding the removal of the content</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>F. Response Time</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>We aim to respond to all takedown requests within [specified time frame, e.g., 10 business days].</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>G. Appeals</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>a. If your content has been removed and you believe this was in error, you may submit an appeal by providing:<br />

            b. Your name and contact information<br />

            c. A description of the content that was removed<br />

            d. The reason for your appeal<br />

            e. Any supporting evidence or documentation<br />

            f. Submit appeals to: [Contact Information]</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>H. Legal Compliance</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>We comply with all applicable laws and regulations regarding content removal and takedown requests. We also cooperate with law enforcement agencies as required.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>I. Policy Updates</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>We may update this Takedown Policy from time to time. Any changes will be posted on</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>Responsibility for Content and Conduct</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>Any information or content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such content originated. You access all such information and content at your own risk, and we are not liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content. You hereby release us from all liability for you having acquired or not acquired Content through the Services. The Services may contain, or direct you to websites containing, information you may find offensive or inappropriate. We can't control that, but please let us know if it happens and we'll try to remedy the situation.<br /><br />

            We can't guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services. You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it. You will keep all your registration information accurate and current. You are responsible for all your activity in connection with the Services.<br /><br />

            The Services may contain links or connections to third-party websites or services that are not owned, operated, or controlled by Tamilezhuthapadi Publications or Storybuzz.org. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and that Tamilezhuthapadi Publications or Storybuzz.org is not responsible for such risks. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize.<br /><br />

            Tamilezhuthapadi Publications or Storybuzz.org has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with through the Services. In addition, Tamilezhuthapadi Publications or Storybuzz.org will not and cannot monitor, verify.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>Software and User Content Policy</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>Software Use</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>Tamilezhuthapadi Publications or Storybuzz.org may provide you with certain software ("Software") for use from the website. Tamilezhuthapadi Publications or Storybuzz.org grants you a limited, personal, non-exclusive, and non-transferable license to use the Software solely for your personal use in connection with authorized purposes associated with the website and Tamilezhuthapadi Publications or Storybuzz.org services.<br /><br />

            Except for the license set forth above, you acknowledge and agree that you have no right to modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance, or exploit the Software in any manner. This limited license terminates automatically, without notice, if you breach any terms of this Agreement. Upon termination of this limited license, you agree to immediately destroy any downloaded Software. Except as stated herein, you acknowledge that you have no right, title, or interest in or to the Software.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>User Content</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>With the exception of Story Content, which is governed by the relevant licenses, by submitting and/or posting any other User Content to the website, you grant Tamilezhuthapadi Publications or Storybuzz.org a royalty-free,

            perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, communicate to the public, perform, and display the content (in whole or in part) worldwide, and/or to incorporate it in other works in any form, media, or technology now known or later developed, for the full term of any rights that may exist in such Content.<br /><br />

            You also permit any user to access, display, view, store, and reproduce such Content in accordance with the relevant license assigned to the Story Content.<br /><br />

            Tamilezhuthapadi Publications or Storybuzz.org reserves the right to remove any published User Content from the site at any time, without notice, for any reason or no reason, at its sole discretion.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>Regarding Documents on this Site</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>Permission to display, copy, and/or download any work, material, or other works of authorship on this site (the "Documents") is hereby granted, provided that:<br />

            (a) The copyright notice set forth on the Document and a statement that the Document is being reproduced under permission of the Copyright Holder both appear on such displayed, copied, or downloaded Document;<br />

            (b) Use or reproduction of such Documents from this site is for informational or personal use only; and<br />

            (c) No modifications of any Documents are made.<br />

            Any other use of the Documents is expressly prohibited.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>Representations and Warranties</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>By using the Services, you represent and warrant that you are of legal age to form a binding contract. If you are not of legal age, you confirm that you have obtained permission from your parent or guardian to use the Services, and they have agreed to these Terms on your behalf, as previously described.<br /><br />

            If you are agreeing to these Terms on behalf of an organization or entity (e.g., as an administrator for your school or district), you further represent and warrant that you are authorized to agree to these Terms on behalf of that organization or entity and to bind them to these Terms.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>Backup of Content/Illustrations</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>Tamilezhuthapadi Publications or Storybuzz.org encourages you to maintain your own backup of any content you publish or post on the website or submit to Tamilezhuthapadi Publications or Storybuzz.org, including, without limitation, your Story Content. Tamilezhuthapadi Publications or Storybuzz.org does not offer a backup service and will not be liable for any loss, modification, suspension, or discontinuation of any content offered on or through the website and Tamilezhuthapadi Publications or Storybuzz.org Services.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>Website Content</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>As a user (including Registered Users) of the website, you agree to the following with respect to Website Content:<br /><br />

            The Tamilezhuthapadi Publications or Storybuzz.org Services contain content owned by Tamilezhuthapadi Publications or Storybuzz.org, its licensors, or third parties ("Website Content"). You may use the Website Content only in connection with the nature of the Tamilezhuthapadi Publications or Storybuzz.org Services and under the terms of the license provided. You may not link to, use, copy, publicly display, publicly perform, communicate, distribute, or otherwise exploit the Website Content in any unlawful or unauthorized manner. Tamilezhuthapadi Publications or Storybuzz.org and/or its licensors or third parties own and retain all proprietary and intellectual property rights in and to the Website Content, the website, and Tamilezhuthapadi Publications or Storybuzz.org Services.<br /><br />

            The various Story Content is licensed to you under specific terms and conditions indicated when accessing the relevant Story Content. You expressly agree that such terms and conditions will govern your use of the relevant Story Content. Except for the rights expressly granted under this Agreement or the relevant Story Content license, you have no other rights to the Story Content.<br /><br />

            http://creativecommons.org/licenses/by/4.0/<br />

            Please note that the pages on this site may contain other proprietary notices and copyright information, the terms of which must be observed and followed.<br /><br />

            Unless otherwise noted, all information, text, articles, data, images, documents (as defined below), software, and other materials (collectively referred to as the "Materials") contained on any page within these sites are copyrighted by Tamilezhuthapadi Publications or Storybuzz.org or a third party and/or released under the Creative Commons license CC-BY-4.0 (collectively referred to as the "Copyright Holder"). Title to copyright in the Materials will always remain with the Copyright Holder. You may only use, download, or copy the Materials contained on these sites in accordance with the Terms and Conditions. Any permitted copies of the Materials must retain the copyright notice and any other proprietary notices contained in such Materials. Unless otherwise provided in the Terms and Conditions, you may not edit, modify, or use the Materials without the express, prior written consent of Tamilezhuthapadi Publications or Storybuzz.org.<br /><br />

            You may not use any content contained in the Materials in any manner that may give a false or misleading impression or statement about Tamilezhuthapadi Publications or Storybuzz.org, the Copyright Holder, or any third party referenced in the Materials. Nothing on these sites shall be construed as conferring any license under any Copyright Holder's intellectual property rights, whether by estoppel, implication, or otherwise. You agree to use these sites and the Materials, services, and products on these sites or those accessible via these sites only for lawful purposes. Tamilezhuthapadi Publications or Storybuzz.org MAY REVOKE ANY OF THE FOREGOING RIGHTS, AS WELL AS ANY ACCESS AND USE AUTHORIZATIONS GRANTED TO USERS OF THESE SITES, AT ANY TIME. Except as specifically provided in these Terms and Conditions (including any applicable supplemental terms), any reproduction, distribution, replication, or retransmission of any information contained on these pages without the prior written consent of Tamilezhuthapadi Publications or Storybuzz.org is strictly prohibited.<br /><br />

            Tamilezhuthapadi Publications or Storybuzz.org respects the rights of all copyright holders and has adopted and implemented a policy that provides for the termination, in appropriate circumstances, of users who infringe the

            rights of copyright holders. If you believe that your work has been copied in a way that constitutes copyright infringement, please contact the website.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>5. Intellectual Property</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>All content on this website, including but not limited to text, graphics, logos, and images, is the property of Tamilezhuthapadi Publications or Storybuzz.org or its content suppliers and is protected by international copyright laws. The compilation of all content on this website is the exclusive property of Tamilezhuthapadi Publications or Storybuzz.org and is also protected by international copyright laws. All software used on this site is the property of Tamilezhuthapadi Publications or Storybuzz.org or its software suppliers and is protected by international copyright laws.<br /><br />

            We respect the intellectual property rights of others and expect our users to do the same. Materials available on or through other websites may be protected by copyright and other intellectual property laws of India and/or other countries. Tamilezhuthapadi Publications or Storybuzz.org is not responsible for the content on external websites. When you access websites other than those operated by Tamilezhuthapadi Publications or Storybuzz.org, the terms of use of those websites govern your use of the material you find there, not this Agreement.<br /><br />

            Tamilezhuthapadi Publications or Storybuzz.org reserves the right, at its sole discretion, to disable and/or terminate the accounts of users who infringe, or are deemed by Tamilezhuthapadi Publications or Storybuzz.org to repeatedly infringe, on Tamilezhuthapadi Publications or Storybuzz.org's or others' copyrights or other intellectual property rights.<br /><br />

            If you believe that any content on our website infringes your intellectual property rights, please contact us with the following information:</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>· Your name and contact information<br />

            · A description of the copyrighted work or other intellectual property you claim has been infringed<br />

            · The URL or location of the infringing content<br />

            · A statement that you have a good faith belief that the use of the material is not authorized by the copyright owner, its agent, or the law<br />

            · A statement, under penalty of perjury, that the information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on behalf of the owner</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>Submit requests to: support@storybuzz.org<br />

            Tamilezhuthapadi Publications or Storybuzz.org will review all claims of copyright or intellectual property infringement promptly and will take appropriate action as required by law.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>6. User Conduct</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>You agree to use the website only for lawful purposes. You agree not to take any action that might compromise the security of the website, render the website inaccessible to others, or otherwise cause damage to the website
            or its content. You agree not to add to, subtract from, or otherwise modify the content on the website, or to attempt to access any content that is not intended for you.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>7. Third-Party Links</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>This website may contain links to third-party websites that are not owned or controlled by Tamilezhuthapadi Publications or Storybuzz.org. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that Tamilezhuthapadi Publications or Storybuzz.org shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>Electronic Communication</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>When you use the Website or send emails or other data, information, or communication to Tamilezhuthapadi Publications or Storybuzz.org, you agree and understand that you are communicating with the Website, Tamilezhuthapadi Publications or Storybuzz.org, and all other Registered Users and Visitors through electronic records which are legally identifiable and enforceable. You consent to receive communications via electronic records from the Website, Tamilezhuthapadi Publications or Storybuzz.org, and all other Registered Users and Visitors as and when posted, communicated, or required.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "50px", fontWeight: "bold" }}>Service of Notice</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "70px" }}>The Registration Data and email ID or your account details of the third-party website through which you register with the Website will be construed as your 'designated electronic address'. Tamilezhuthapadi Publications or Storybuzz.org, other Registered Users, Visitors, third parties, and law enforcement agencies may communicate with you on your designated electronic address, which will be deemed adequate service of notice/electronic record.<br /><br />

            By impliedly or expressly accepting this User Agreement, you also accept and agree to be bound by various policies of Tamilezhuthapadi Publications or Storybuzz.org as provided from time to time in various hyperlinks on the Website.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>8. Disclaimer of Warranties</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>This website is provided on an "as is" and "as available" basis. Tamilezhuthapadi Publications or Storybuzz.org makes no representations or warranties of any kind, express or implied, as to the operation of this website or the information, content, materials, or products included on this website. You expressly agree that your use of this website is at your sole risk.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>9. Limitation of Liability</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>In no event shall Tamilezhuthapadi Publications or Storybuzz.org, its directors, officers, employees, or agents be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of this website, the content, or the services offered through this website.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>Your Privacy and Personal Information</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>Tamilezhuthapadi Publications or Storybuzz.org takes the privacy of its users seriously<br /><br />

            By using the Website and/or by providing your information, you consent to the collection and use of the information you disclose on the Website by Tamilezhuthapadi Publications or Storybuzz.org in accordance with the Tamilezhuthapadi Publications or Storybuzz.org Privacy Policy.<br /><br />

            As an individual, school, district, librarian, reading program manager, or teacher, you represent and warrant that you are solely responsible for complying with all applicable laws, including obtaining advance written consent from all parents or guardians whose children under 18 years of age will be accessing the Services. You are responsible for understanding how any Publisher Software (defined and described below) that you install on behalf of yourself or other users may collect and use information of users of the Services. When obtaining consent, you must provide parents and guardians with our Privacy Policy. If you are a teacher, you represent and warrant that you have permission and authorization from your school and/or district to use the Services as part of your curriculum. For purposes of legal compliance, you represent and warrant that you are entering into these Terms on behalf of your school and/or district.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>References to Products or Services</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>Information published by Tamilezhuthapadi Publications or Storybuzz.org on these sites may contain references or cross-references to Tamilezhuthapadi Publications or Storybuzz.org products or services that are not announced or available in your country. Such references do not imply that Tamilezhuthapadi Publications or Storybuzz.org intends to announce such products or services in your country. Please contact Tamilezhuthapadi Publications or Storybuzz.org at contact@Tamilezhuthapadi Publications or Storybuzz.org.org.in for information regarding the products and services that may be available to you.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>10. Indemnification</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>You agree to indemnify, defend, and hold harmless Tamilezhuthapadi Publications or Storybuzz.org, its officers, directors, employees, agents, licensors, and suppliers from and against all claims, losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these terms of use or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the website using your account.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>11. Content Usage and Prohibitions</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>The materials displayed or performed on the Services, including text, graphics, articles, photos, images, illustrations, and user submissions ('Content'), are protected by copyright and intellectual property laws. You agree to abide by all copyright notices, trademark rules, and restrictions contained in any Content accessed through the Services. You may not:<br /><br />
            · Use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, or exploit for any purpose any Content not owned by you without prior consent of the owner.<br /><br />

            · Modify, publish, participate in the transfer or sale of, reproduce, create derivative works based on, or exploit the Services and the website beyond the permissions granted by the Creative Commons License mentioned on the website.<br />

            <b>  · Violation of Terms of Use</b><br />

            · Your right to use or access the Services will be automatically terminated if you violate these Terms of Use or any published Tamilezhuthapadi Publications or Storybuzz.org policies. We reserve the right, at our sole discretion, to terminate your access to all or part of the Services for any reason, with or without notice.<br />

            · By violating these Terms of Use, you are breaching your agreement with Tamilezhuthapadi Publications or Storybuzz.org and may be infringing on intellectual property rights, engaging in unfair competition, and violating other applicable laws.<br />

            · Tamilezhuthapadi Publications or Storybuzz.org does not make any representations or warranties regarding the content contained in or accessed through our services. We are not responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the services.<br />

            · We do not represent or warrant that our website and services will be uninterrupted or error-free, that any defects will be corrected, or that our website or the servers that make it available are free of viruses or other harmful components.<br /><br />

            <b>· Disclaimer of Warranties</b><br />

            o The services, content, website, and any software are provided on an "as-is" basis, without warranties of any kind, either express or implied. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or that the use of the services will be uninterrupted or error-free.<br /><br />

            <b>· Assumption of Risk</b><br />

            o Except as expressly provided otherwise, you assume all risks concerning the suitability and accuracy of the information within our website, content, publications, and documents. Our website, content, publications, and documents may contain technical inaccuracies or typographical errors. Tamilezhuthapadi Publications or Storybuzz.org assumes no responsibility for and disclaims all liability for any such inaccuracies, errors, or omissions in our website, content, publications, and documents, as well as in any other references.<br /><br />

            <b> · Limitation of Liability</b><br />

            o Applicable law may not allow certain limitations or exclusions on warranties; therefore, the above limitations shall apply to you to the fullest extent permissible under applicable law.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>12. Governing Law</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>These terms of use shall be governed by and construed in accordance with the laws of Georgia,United States, without regard to its conflict of law principles.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>13. Contact Information</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>If you have any questions about these terms of use, please contact us at support@storybuzz.org</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' sx={{ width: 'auto', marginTop: "10px", marginLeft: "30px", fontWeight: "bold" }}>14. Changes to Terms of Use</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: 'auto', marginTop: "5px", marginLeft: "50px" }}>We reserve the right to modify these terms of use at any time. Any changes will be effective immediately upon posting on the website. Your continued use of the website after any changes are posted constitutes your acceptance of the new terms.</Typography>        
        </Grid>
        <Grid>
        </Grid>
      </Grid>
    </Box>
    </Box>
  );
};

export default Terms;
