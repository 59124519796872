import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import aboutimg from "../../assets/aboutimg.jpg";
import "../CSS/CategoriesList.css";
import axiosInstance from "../utils/AxiosInstance";
import PaymentPopup from "./Paymentpop";
import axios from 'axios';
 
const Donate = () => {
  const [isVisible, setIsVisible] = useState(true);
  const intervalRef = useRef(null);
 
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 1000);
 
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
 
  const blinkingStyle = {
    opacity: isVisible ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };
 
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };
 
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
 
 
  const [amount, setAmount] = useState("");
  const paypalRef = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState('');
 
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };
 
  return (
    <Box sx={{ margin: "100px 40px 80px" }} height={'100%'}>
      <Box
        sx={{
          position: "relative",
          height: "auto",
          width: "100%",
          display: "flex",
          // justifyContent: 'center',
          // alignItems: 'center',
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            //  backgroundImage: `url(${aboutimg})`,
            backgroundColor: "#1ab5f8f6",
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.1,
            zIndex: -1,
          }}
        />
        <Grid container direction="column" sx={{ margin: "20px 80px 50px" }}>
          <Grid item>
            <Typography
              variant="h4"
              sx={{
                color: "black",
                marginTop: "30px",
                marginLeft: "30px",
                fontWeight: "bold",
              }}
            >
              Donate :
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginTop: "30px",
                marginLeft: "30px",
                fontWeight: "bold",
              }}
            >
              Why Choose to Donate:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{ width: "auto", marginTop: "5px", marginLeft: "30px" }}
            >
              - <b>Transparency:</b> We ensure that your donation is used
              effectively to support our mission.
              <br />- <b>Impact:</b> Your donation directly impacts children's
              lives by providing them with the tools they need to succeed.
              <br />- <b>Community:</b> Join a community of supporters who
              believe in the power of storytelling to change lives.
            </Typography>
          </Grid>
          <Grid item>
            
              <PaymentPopup
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                amount={amount}
                setAmount={setAmount}
                email={email}
                setEmail={setEmail}
              />              
              
              

              <Box display="flex" flexDirection="column">
                <Typography textAlign="center">(or)</Typography>
                <Typography textAlign="center" pt={2} variant="h6" fontWeight="bold">Please make <Typography component="span" variant="h6" fontWeight="bold" color="error">Zelle</Typography> payment to:</Typography>
                <Typography textAlign="center" fontWeight="bold">Recipient Type: Business</Typography>
                <Typography textAlign="center" fontWeight="bold"> Name: Tamilezhuthapadi Publications LLC</Typography>
                <Typography textAlign="center" fontWeight="bold">Email address: <Typography component="span" color="error" fontWeight="bold" >Payment@tamilezhuthapadi.org</Typography></Typography>
              </Box>
              </Grid>
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginTop: "30px",
                marginLeft: "30px",
                fontWeight: "bold",
              }}
            >
              Support Our Mission
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                color: "black",
                marginTop: "30px",
                marginLeft: "30px",
                fontWeight: "bold",
              }}
            >
              Make a Difference Today
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{ width: "auto", marginTop: "5px", marginLeft: "30px" }}
            >
              Your generous contribution helps us provide educational programs
              that ignite a passion for reading and writing in children. By
              donating, you are helping us preserve cultural heritage and foster
              creativity in the next generation.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                color: "black",
                marginTop: "30px",
                marginLeft: "30px",
                fontWeight: "bold",
              }}
            >
              How Your Donation Helps:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{ width: "auto", marginTop: "5px", marginLeft: "30px" }}
            >
              - <b>Support Programs:</b> Your donation funds interactive
              storytelling sessions and creative writing workshops that reach
              thousands of children.
              <br />- <b>Provide Resources:</b> Help us provide books, learning
              materials, and teacher training to underprivileged schools.
              <br />- <b>Expand Reach:</b> With your support, we can extend our
              programs to more communities and make a bigger impact.
            </Typography>
          </Grid>
        
          <Grid item>
            <Typography
              variant="h4"
              sx={{
                textAlign:'center',
                paddingTop: "30px",
                paddingBottom: "30px",
                color: "red",
              }}
            >
              Together, We Can Create a Brighter Future!
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{ width: "auto", marginTop: "5px", marginLeft: "30px" }}
            >
              Your support is invaluable in helping us achieve our goals. Every
              donation, big or small, makes a difference. Let's work together to
              inspire the next generation of readers and writers.
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ textAlign: "right", marginTop: "20px" }}
          >
              {/* <div id="paypal-donate-button-container"></div> */}
            <Box>
              {/* <Button variant="contained" color="primary" onClick={handleOpenPopup}>
                Donate Here
              </Button> */}
 
              
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
 
export default Donate;
 