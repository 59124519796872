import React from 'react';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {

        common:{
            black: "#000000",
            white: "#ffffff",
        },

        primary: {
        main: "#00a013",
        },

        secondary: {
            main: "#ca0005",
        },

        // background:{
        //     default: "#cee6f0"
        // },

        text:{
            primary: "#000000",
            secondary: "#00a013"            
        },
    },

    typography: {
        fontFamily: "'Noto Serif Tamil', Mukta Malar",
    }
  });

export default theme;
