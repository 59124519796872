import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  Card,
  CircularProgress,  
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import axiosInstance from "../utils/AxiosInstance";
import { AuthContext } from "../../hooks/context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ShoppingCartItem } from "../common/ShoppingBookCard";
import OrderSummary from "./OrderSummary";
import { CartContext } from "../../hooks/context/CartContext";
import PurchaseLoading from "../common/PurchaseLoading";
import { LanguageContext } from "../../hooks/context/LanguageContext";

const ShoppingCart = () => {
  const { language } = useContext(LanguageContext);
  const [activeBooks, setActiveBooks] = useState([]);
  const [bookCounts, setBookCounts] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [discount, setDiscount] = useState(0);  
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null);
  const { incrementCartCount, decrementCartCount } = useContext(CartContext);

  const navigate = useNavigate();
  const userId = user?.id;

  useEffect(() => {
    //console.log("Updated activeBooks:", activeBooks);
  }, [activeBooks]);

  useEffect(() => {
    const fetchActiveBooks = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/purchase/getActiveCartBooks/${userId}`
        );
         
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
        cartData.forEach((item) => {                    
          counts[item.book_id] = item.quantity;
          total += item.price * item.quantity;
        });            
            
        setBookCounts(counts);
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); 
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount);    
        setTotalPriceWithTax(response.data.finalTotal);
        setDiscount(response.data.discount);
         
      } catch (error) {
        //console.error("Error fetching active books:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveBooks();
  }, [userId, language]);
  

  const updateCartInBackend = async (bookId, newQuantity) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/purchase/updateCartQuantity",
        {
          userId: userId,
          bookId,
          newQuantity,
        }
      );

      if (response.status === 200) {
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
        cartData.forEach((item) => {
          counts[item.book_id] = item.quantity;
          total += item.price * item.quantity;
        });
        setBookCounts(counts);
        //console.log("update", counts);
        
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); 
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount); 
        setTotalPriceWithTax(response.data.finalTotal);
        setDiscount(response.data.discount || 0);
      }
    } catch (error) {
      //console.error("Error updating cart:", error);
    } finally {
      setLoading(false);
    }
  };

const handleQuantityChange = async (bookId, delta) => {        
  
  setLoading(true);

  setBookCounts((prevCounts) => {
    const currentCount = prevCounts[bookId] || 0;
    const newCount = Math.max(currentCount + delta, 0);
    
    const updatedCounts = {
      ...prevCounts,
      [bookId]: newCount,
    };

    updateCartInBackend(bookId, newCount)
      .then(() => {      
      })
      .catch((error) => {
        //console.error("Error updating cart in backend:", error);          
        setBookCounts(prev => ({
          ...prev,
          [bookId]: currentCount
        }));
      })
      .finally(() => {
        setLoading(false);
      });

    return updatedCounts;
  });
  
  if (delta > 0) {
    incrementCartCount(1);
  } else if (delta < 0) {
    decrementCartCount(1);
  }
};


  const handleClickOpen = (bookId) => {
    setBookToDelete(bookId); 
    setOpen(true); 
  };

  const handleClose = () => {
    setOpen(false);
    setBookToDelete(null); 
  };

  const handleConfirmDelete = () => {
    if (bookToDelete !== null) {
      handleDelete(bookToDelete); 
      handleClose(); 
    }
  };

const handleDelete = async (bookId) => {
    setLoading(true);
    try {      
       const response = await axiosInstance.delete(
          `/purchase/deleteFromCart/${userId}/${bookId}`
        );
  
      if (response.status === 200) {
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
  
        cartData.forEach((item) => {
          counts[item.book_id] = item.quantity;
          total += item.price * item.quantity;
        });
        
        setBookCounts(counts);                
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); 
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount); 
        setTotalPriceWithTax(response.data.finalTotal);
        setDiscount(response.data.discount || 0);
        decrementCartCount(bookCounts[bookId]);  
      }
    } catch (error) {
      //console.error("Error removing book from cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBookClick = (book) => {
    navigate(`/book/${book.book_id}`);        
  };

  const handlePlaceOrder = async () => {       
    const orderDetails = {
      userId,
      books: activeBooks.map((book) => ({
        book_id: book.book_id,
        count: bookCounts[book.book_id] || 1,
      })),
      totalPrice,
    };

    try {      
      const response = await axiosInstance.post(
        "/purchase/orders",
        orderDetails
      );

      if (response.status === 200) {        
          navigate("/checkout");        
      } else {
        //console.log("Order could not be placed.");
      }
    } catch (error) {
      //console.error("Error placing order:", error);
    }
  };

  const goBack = () => {
    window.history.back();
  };

 

  return (
    <Box mt={6} p={2} minHeight="100vh">
      {loading && (
        <PurchaseLoading/>
      )}
      <Box display="flex" flexDirection="row" alignItems="center" pl={2} pb={2}>
        <IconButton
              onClick={goBack}
              sx={{
                width:50,
                height:50,
                color: "black",
                bgcolor: "#fff",
                "&:hover": { bgcolor: "darkgreen", color: "#fff" },
               
              }}
              aria-label="Go Back"
            >
              <ArrowBackIcon sx={{ fontSize: 30 }} />
            </IconButton>

        <Typography
          fontSize='30px'          
          sx={{ fontWeight: "bold", pl:2}}
        >
          Shopping Cart
        </Typography>
      </Box>
    

      {activeBooks.length > 0 ? (
      <Grid container spacing={4}>      
        <Grid item xs={12} md={8}>
          <Box>            
              {activeBooks.map((book) => (
                <Box key={book.book_id} sx={{ mb: 2 }}>
                  {" "}
                  <ShoppingCartItem
                    book={book}
                    handleIncrement={() => handleQuantityChange(book.book_id, 1)}
                    handleDecrement={() => handleQuantityChange(book.book_id, -1)}
                    handleDelete={() => handleClickOpen(book.book_id)}
                    handleBookClick={handleBookClick}
                    bookCounts={bookCounts}   
                    mb= {2}
                    p= {2} 
                    ml= {5}                            
                  />

                </Box>
              ))}         
          </Box>   
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="outlined" size="large" onClick={()=>navigate("/books")}>Add More</Button>
          </Box>

        </Grid>      

        <Grid item xs={12} md={3} ml={5}>
          <OrderSummary
            totalPrice={totalPrice}
            discount={discount}
            shippingCharge={shippingCharge}
            taxAmount={taxAmount}
            totalPriceWithTax={totalPriceWithTax}
            onPlaceOrder={handlePlaceOrder}
            showButton={true}
          />
        </Grid>   
      </Grid>
      ) : (    
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="80vh">
          <ShoppingCartIcon sx={{ color: '#000', fontSize: '100px',cursor:'pointer' }} />
          <Typography textAlign="center" variant="h5">Your Story Buzz Cart is empty.</Typography>
          <Typography><Link to="/books">Continue Shopping or Reading...</Link></Typography>
        </Box>
      )}     


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this book from your cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="secondary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShoppingCart;
