// PasswordResetRequest.js
import React, { useState } from 'react';
import axiosInstance from "../utils/AxiosInstance";
import { Container, TextField, Button, Typography, Box, Grid, ThemeProvider, InputAdornment, IconButton, Snackbar, Alert } from '@mui/material';
import theme from '../ui/Theme';
import bgImg from "../../assets/final/Login.jpg"
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotMatch from '@mui/icons-material/HighlightOff';
import Navbar from '../layout/Navbar';
import PasswordValidator from './PasswordValidator'
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordReset = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [newPasswordMismatch, setNewPasswordMismatch] = useState(false);
    const [message, setMessage] = useState('');
    const location = useLocation();
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [validateErrorMessage, setValidateErrorMessage] = useState('')
    const [showPassword,setShowPassword] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [validations, setValidations] = useState({
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isLongEnough: false,
    });

  
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const validatePassword = (password) => {
      const hasUppercase = /[A-Z]/.test(password);
      const hasLowercase = /[a-z]/.test(password);
      const hasNumber = /[0-9]/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      const isLongEnough = password.length >= 8;
  
  
      setValidations({
        hasUppercase,
        hasLowercase,
        hasNumber,
        hasSpecialChar,
        isLongEnough,
      });
    };

    const navigate = useNavigate();
  
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axiosInstance.post('/auth/reset-password', { token, newPassword });        
        setSnackbarMessage(response.data.message);        
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/auth/login');
        }, 1000);
      } catch (error) {
        setMessage(error.response.data.error);
      }
    };

    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };

    const handlePasswordFocus = () => {
      setPasswordFocused(true);
    };
    
    const handlePasswordBlur = () => {
      if (!newPassword) {
        setPasswordFocused(false);
      }
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    
    const handlePasswordChange = (e) => {
      const newPassword = e.target.value;
      setNewPassword(newPassword);
      validatePassword(newPassword);
      setNewPasswordMismatch("")
      setValidateErrorMessage("");
    };

    if (!token) {      
      return <Box>Error: Invalid password reset link. Please check your reset link.</Box>;
    }
  

  return (
    <ThemeProvider theme={theme}>
        <Navbar/>
        <Box display="flex" justifyContent="flex-end" alignItems="center" position="relative" minHeight="100vh" pt={10} pb={5} sx={{justifyContent: {xs:"center", md:"flex-end"}, pr:{md:"150px"}, backgroundImage: `url(${bgImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
        <Grid container direction="column" justifyContent="center" alignItems="center" boxShadow={1} borderRadius="50px" width="380px" p={5} sx={{bgcolor:{xs:"#ffffffff", md:"#ffffff39", }}}>
                <Grid item xs={8} sm={8} md={5} lg={3}>
                    <Typography variant="h4" component="h1" gutterBottom>Password Reset</Typography>
                    <form onSubmit={handleSubmit}>

                    <TextField required label="New Password" type= {showPassword ?'text':'password'} fullWidth margin="normal" value={newPassword} onFocus={handlePasswordFocus} onBlur={handlePasswordBlur} onChange={handlePasswordChange} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    />

                    {passwordFocused && (
                    <PasswordValidator validations={validations} validateErr={validateErrorMessage}/>            
                    )}
                        <TextField required label="Confirm New Password" type="password" fullWidth margin="normal" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}
                            InputProps={{ endAdornment: (                                
                                <InputAdornment position="end">
                                   { newPassword && confirmNewPassword && newPassword === confirmNewPassword ? (
                                    <CheckCircleIcon color="success" />
                                    ) : <NotMatch color='error'/>
                                    }
                                </InputAdornment>
                                
                            ),
                            }}/>
                        <Button type="submit" variant="contained" color="primary" fullWidth sx={{mt:"20px", color: '#ffffff', backgroundColor:"#000000", '&:hover': {backgroundColor: '#000000ff', borderColor: '#000000', },}}>
                            Reset Password
                        </Button>
                    </form>
                    {message && (
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        {message}
                    </Typography>
                    )}
                </Grid>
            </Grid>

            <Snackbar
              key={snackbarKey}
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
        </Box>
    </ThemeProvider>
  );
};



export default PasswordReset;
