import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Paper,
  Box,
  Radio,
  Typography,
  Button,
} from '@mui/material';
import image1 from '../../assets/newscene1.svg';
// import image2 from '../../assets/newscene2.svg';
import image3 from '../../assets/newscene3.svg';
import image4 from '../../assets/newscene4.svg';
import image5 from '../../assets/newscence5.svg';
import image6 from '../../assets/newscence6.svg';


const ScenesDialog = ({ open, onClose, onSelectColor, onSelectImage, setEmpty }) => {
  const handleColorClick = (color) => {
    onSelectColor(color);
    onClose();
  };

  const handleImageClick = (image) => {
    onSelectImage(image);
    onClose();
    // setEmpty(true)
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select Scene</DialogTitle>
      <DialogContent>
        <Box sx={{ marginBottom: '20px' }}>
          <Typography variant="subtitle1">Background Color</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
            {[
              '#f5cba7 ', '#f1948a ', '#FFD700', '#9ACD32', '#32CD32',
              '#00FA9A', '#00CED1', '#1E90FF', '#4169E1', '#8A2BE2',
              '#5d6d7e', '#696969', '#708090', '#A9A9A9', '#000000', '#FFFFFF'
            ].map((color, index) => (
              <Paper
                key={index}
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: color,
                  borderRadius: '50%',
                  cursor: 'pointer',
                  border: '2px solid #fff',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => handleColorClick(color)} // Handle color click
              />
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle1">Scene Images</Typography>
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            {[
              image1, image3, image4, image5, image6,
            ].map((image, index) => (
              <Grid item xs={4} key={index}>
                <Paper
                  sx={{
                    padding: '10px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      border: '2px solid #3f51b5',
                    },
                  }}
                  onClick={() => handleImageClick(image)} // Handle image click
                >
                  <img src={image} alt={`scene ${index + 1}`} style={{ width: '100%' }} />
                  <Radio sx={{ display: 'block', marginTop: '10px' }} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FF0004',
              color: 'white',
              '&:hover': {
                backgroundColor: '#D00000',
              },
            }}
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ScenesDialog;
