import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../hooks/context/AuthContext';
import Loading from "../common/Loading";

const EditorRoute = ({ element: Element, ...rest }) => {
    const { isAuthenticated, user, loading} = useContext(AuthContext);

    if (loading) {
        // Optionally, you can render a loading spinner here
        return <Loading/>
    }

    if (!isAuthenticated || user?.role !== 'editor') {
        return <Navigate to="/auth/login" />;
    }

    return <Element {...rest} />;
};

export default EditorRoute;
