// AuthContext.js
import { createContext, useState, useEffect } from 'react';
import axiosInstance from "../../components/utils/AxiosInstance";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const checkAuth = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/auth/auth-check');
      if (response.data.isAuthenticated) {
        setIsAuthenticated(true);
        setUser(response.data.user);
        //console.log(response.data.user);
      } else {
        setIsAuthenticated(false);
        setUser(null);        
      }
    } catch (error) {
      setIsAuthenticated(false);
      setUser(null);      
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const logout = async (onLogoutSuccess) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/auth/logout');
      if (response.status === 200 && response.data.message === "Logout successful") {
        //console.log(response.data.message);
        if (onLogoutSuccess) onLogoutSuccess(); // Trigger the Snackbar
      }
    } catch (error) {
      //console.error("Logout failed:", error.response.data.error);
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, checkAuth, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
