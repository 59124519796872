import  { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/layout/Layout';
import AdminPanel from './components/adminPanel/AdminPanel';
import EditorPanel from './components/editorPanel/EditorPanel';
import { AuthProvider } from './hooks/context/AuthContext';
import { LanguageProvider } from './hooks/context/LanguageContext'
import PrivateRoute from './components/routes/PrivateRoute';
import PasswordResetForm from './components/authentication/PasswordResetForm';
import PasswordReset from './components/authentication/PasswordReset';
import PublicRoute from './components/routes/PublicRoute';
import ContactUs from './components/pages/ContactUs';
import Terms from './components/pages/TermsAndCondition';
import './components/CSS/CategoriesList.css'
import AdminRoute from './components/routes/AdminRoute';
import Donate from './components/donate/Donate';
import Loading from './components/common/Loading';
import UserRoute from './components/routes/UserRoute';
import EditorRoute from './components/routes/EditorRoute';
import Paymentpopup from './components/donate/Paymentpop';
import StroyBook from './components/bookCreator/StroyBook';
import DonationSuccess from './components/donate/DonationSuccess';
import DonationReject from './components/donate/DonateReject';
import { Dialog, DialogContent, DialogTitle, Hidden } from "@mui/material";
import React, { useState, useEffect } from "react";
import VerifyEmail from './components/authentication/EmailVerification';
import ShoppingCart, { AddCart } from './components/purchase/Cart';
import Checkout from './components/purchase/Checkout';
import MobileBlocker from './components/layout/MobileBlocker';
import { CartProvider } from './hooks/context/CartContext';
import AllBooks from './components/pages/AllBooks';
import User from './components/user/User';


const Home = lazy(() => import('./components/pages/Home'));
const Login = lazy(() => import('./components/authentication/Login'));
const Register = lazy(() => import('./components/authentication/Register'));
const BookView = lazy(() => import('./components/bookViewer/BookView'));
const BookDescription = lazy(() => import('./components/pages/BookDescription'));
const BookCreationForm = lazy(() => import('./components/bookCreator/BookCreateForm'));
const AboutUs = lazy(() => import('./components/pages/AboutUs'));


function App() {

  return (
    <>
    <LanguageProvider>       
        <Suspense fallback={<Loading/>}> 
          <BrowserRouter>
            <AuthProvider>
            <CartProvider>
            <MobileBlocker/>
            <Routes>              
                <Route path="/" element={<Layout/>}>
                  <Route index element={<Suspense fallback={<Loading/>}><Home/></Suspense>} />                        
                  <Route path="/books" element={<AllBooks/>}/>                          
                  <Route path="/book/:bookId" element={<BookDescription/>} />
                  <Route path='/user-dashboard' element={<UserRoute element={User}/>}/>
                  <Route path='/create/book/form' element={<BookCreationForm/>}/>
                  <Route path='/about' element={<AboutUs/>}/>
                  <Route path='/contact' element={<ContactUs/>}/>
                  <Route path='/terms' element={<Terms/>}/>  
                  <Route path='/donate' element={<Donate/>}/>                                                                 
                  <Route path='/addcart' element={<ShoppingCart/>}/>  


                  <Route path='/checkout' element={<Checkout/>}/>  
                </Route>

                <Route path='/donate/failed' element={<DonationReject/>}/>    
                <Route path='/donate/success' element={<DonationSuccess/>}/>                  
                <Route path="/auth/verify-email" element={<VerifyEmail/>} />
                <Route path="/auth/login" element={<Suspense fallback={<Loading/>}> <PublicRoute element={Login}/> </Suspense>}/>
                <Route path="/auth/register" element={<Suspense fallback={<Loading/>}><PublicRoute element={Register}/> </Suspense>}/>
                <Route path="/auth/reset-password/form" element={<PublicRoute element={PasswordResetForm}/>} />
                <Route path="/auth/reset-password" element={<PublicRoute element={PasswordReset}/>} />
                <Route path='/create/book' element={<PrivateRoute element={StroyBook} />}/>
                <Route path="view/:url" element={<Suspense fallback={<Loading/>}><BookView/></Suspense>}/>              
                <Route path='/admin' element={<AdminRoute element={AdminPanel} />}/>
                <Route path='/editor' element={<EditorRoute element={EditorPanel} />}/>
                <Route path='/story' element={<StroyBook/>}/>                
                              
            </Routes> 
            </CartProvider>                              
            </AuthProvider>
          </BrowserRouter>
        </Suspense>         
    </LanguageProvider> 
    </>
  );
}

export default App;