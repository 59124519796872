import React from 'react';
import { Fab, Box,useMediaQuery, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DonateIcon from '@mui/icons-material/VolunteerActivism'; 

const FloatingDonateButton = () => {
  const { t } = useTranslation('home');

  const isMobile = useMediaQuery('(max-width:600px)'); 

  
  if (isMobile) {
    return null;
  }

  return (
    <Box sx={{ position: 'fixed', bottom: 15, right: 70, zIndex:2}}>
      <Link href="/donate" style={{ textDecoration: 'none' }}>
        <Fab
          variant="extended"
          color="primary"
          aria-label="donate"
          
        >
          <DonateIcon sx={{ mr: 1 }} />
          {t("footer.donate")}
        </Fab>
      </Link>
    </Box>
  );
};

export default FloatingDonateButton;
