import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Box, Grid, Divider } from '@mui/material';
import contactusimg from '../../assets/contactusimg.jpg'
import contactusnew from '../../assets/contactusnew.jpg'
import FloatingDonateButton from '../donate/FloatingDonateButton';
import contactImage from '../../assets/ContactUs.png'
 
const ContactUs = () => {
  return (
    <Box  id='home'
    sx={{margin:"100px 40px 50px",}}
    >
    <Box
      sx={{
        position: 'relative',      
        width: '100%',
        display: 'flex',
         justifyContent: 'center',
        // alignItems: 'center',
        overflow: 'hidden',
        borderRadius:'20px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
         // backgroundImage: `url(${contactusnew})`,
          backgroundColor:'#1ab5f8f6',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.1,
          zIndex: -1,
        }}
      />
      <Grid container direction="column" sx={{margin:"20px 80px 30px"}}>
        <Grid item>
          <Typography variant="h4" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            Contact Us :
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            We'd Love to Hear from You!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'black', marginTop: "20px", marginLeft: "30px", fontWeight: "bold" }}>
            How to Reach Us:
          </Typography>
        </Grid>
       <Grid item sx={{marginTop: "10px"}}>
            <Typography
              component="a"
              href="mailto:support@storybuzz.org"
              variant="body1"
              sx={{
                color: 'blue',
                marginTop: '50px',
                marginLeft: '60px',
                fontSize: "20px",
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                }
              }}
            >
              Email: support@storybuzz.org
            </Typography>
          </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "10px", marginLeft: "30px" }}>Get Involved: Want to bring StoryBuzz to your school? Interested in volunteering or partnering with us? Reach out, and let's make stories come alive and buzz together!</Typography>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "15px", marginLeft: "30px" }}>Follow Us: Stay up to date with our latest news, events, and storytelling tips by following us on social media:</Typography>
        </Grid>
        <Grid item gap={2} sx={{ fontWeight: "bold" }}>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "15px", marginLeft: "50px", fontWeight: "bold" }}>· Facebook: facebook.com/StoryBuzz<br />
 
            · Twitter: twitter.com/StoryBuzz<br />
 
            · Instagram: instagram.com/StoryBuzz<br />
 
            · Tiktok: tiktok.com/StoryBuzz</Typography>
        </Grid>
        <Grid item>
            <Typography variant='body1'sx={{ width: "auto", marginTop: "15px", marginLeft: "30px",  }}>Send Us a Message: Fill out the form below, and we'll get back to you as soon as possible.</Typography>
        </Grid>
        <Grid item sx={{ marginTop: "10px"}}>
            <Typography
              variant='body1'
              sx={{
                width: "auto",
                marginTop: "15px",
                marginLeft: "30px",
                fontSize: "20px",
                // textDecoration: 'none',
                textDecoration: 'underline',
                color: 'blue',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }
              }}
              component="a" // Renders Typography as an <a> element
              href="https://tinyurl.com/contactbuzz" // URL to redirect
              target="_blank" // Opens in a new tab
              rel="noopener noreferrer" // Security best practice
            >
              Contact Form
            </Typography>
          </Grid>
            <Box width={150} height={150}  component={'img'} src={contactImage} ml={'30px'} pt={'5px'}></Box>
        <Grid item>
            <Typography variant='body1'sx={{ width: "auto", marginTop: "15px", marginLeft: "30px",  }}>Join Our Newsletter: Subscribe to our monthly newsletter to receive updates on new programs, upcoming events, and special offers.
              <Typography  
              component="a"
              href="https://tinyurl.com/contactbuzz" // URL to redirect
              target="_blank"
              rel="noopener noreferrer"
               > [Subscribe Here]</Typography></Typography>
        </Grid>
        <Grid item>
            <Typography variant='body1'sx={{ width: "auto", marginTop: "15px", marginLeft: "30px",  }}>Thank you for being a part of the StoryBuzz community! Your support helps us inspire the next generation of readers and writers.</Typography>            
        </Grid>   
        
        
          
        <Grid item>
          <Typography display={'flex'} justifyContent={'center'} color={'#191970'} fontWeight={'bold'} >support@storybuzz.org</Typography>      
        </Grid>
      </Grid>
    </Box>
 <FloatingDonateButton />

    </Box>
  );
};
 
export default ContactUs;
 