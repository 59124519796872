import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import mobileNotSupportedImg from '../../assets/no-phone_13350344.png';

// const MobileBlocker = ({ children }) => {  
const MobileBlocker = ( ) => {  
  const [alertOpen, setAlertOpen] = useState(true);
  const theme = useTheme();
  
  const handleCloseAlertDialog =()=>{
    setAlertOpen(false)
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Dialog open={alertOpen} aria-labelledby="mobile-blocker-dialog" fullWidth>
        <DialogContent sx={{ textAlign: 'center' }}>
          <img
            src={mobileNotSupportedImg}
            alt="Mobile not supported"
            style={{ width: '100%', maxWidth: '300px', margin: '0 auto' }}
          />
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            For the best viewing experience, please visit our website on a desktop browser. Some features may not display correctly on mobile devices. Thank you for your understanding!
          </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseAlertDialog}>Continue</Button>
        </DialogActions>
      </Dialog>
    );
  }

//   return <>{children}</>;
};

export default MobileBlocker;
