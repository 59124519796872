import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../hooks/context/AuthContext';
import Loading from "../common/Loading";


const PublicRoute = ({ element: Element }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);
  if (loading) {
    // Optionally, you can render a loading spinner here
    return <Loading/>
}
  return isAuthenticated ? <Navigate to="/" /> : <Element/>;
};

export default PublicRoute;
