// PayPalButtonComponent.js
import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";

const PayPalButtonComponent = ({ totalPriceWithTax, onApprove, onError }) => {
  return (
    <PayPalButtons
      key={totalPriceWithTax}
      style={{ layout: "vertical" }}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: totalPriceWithTax.toFixed(2),
              },
            },
          ],
        });
      }}
      onApprove={onApprove}
      onError={onError}
    />
  );
};

export default PayPalButtonComponent;