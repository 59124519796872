import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../utils/AxiosInstance';
import { Box, Grid, ThemeProvider, Typography } from '@mui/material';
import theme from '../ui/Theme';
import Logo from '../../assets/logonew.png';

function VerifyEmail() {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const verifyEmail = async () => {
    try {
      const response = await axiosInstance.post('/auth/verify-email', { token });
      if (response.data.message === 'Email verified successfully') {
        setMessage('Email verified successfully! You can now log in.');
        setTimeout(() => window.location.href = '/auth/login', 3000);
      }
    } catch (error) {
      setMessage(error.response.data.error || 'Error verifying email.');
    }
  };

  useEffect(() => {
    if(token){
    verifyEmail();
  }
  }, [token]);

  if (!token) {      
    return <Box>Error: Invalid verification link. Please check your verification link.</Box>;
  }


  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Grid container flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
        <Grid item>
          <Box component='img' src={Logo} width={220} height={200}/>
        </Grid>
        <Grid item pt={5}>
          <Typography textAlign="center" variant="h4" fontWeight="bold">Email Verification</Typography>
          <Typography pt={1} textAlign="center" variant="body" fontWeight="bold">{message}</Typography>
        </Grid>

      </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default VerifyEmail;
