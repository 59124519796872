import React, { useEffect, useState, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  TextField,
  Grid,
  Box,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormLabel,
  Select,
  InputLabel,
  Divider,
  CardMedia,
  CardActions,
  InputAdornment,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../utils/AxiosInstance";
import BookCardTwo from "../common/BookCardTwo";
import { AuthContext } from "../../hooks/context/AuthContext";
import { useNavigate, useLocation, json } from "react-router-dom";
import { Add, Delete, Remove } from "@mui/icons-material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OrderSummary from "./OrderSummary";
import { ShoppingCartItem } from "../common/ShoppingBookCard";
import { CartContext } from "../../hooks/context/CartContext";
import PurchaseLoading from "../common/PurchaseLoading";
import { LanguageContext } from "../../hooks/context/LanguageContext";
import Address from "./Address";
import secureLogo from '../../assets/scure.png';
import PayPalButtonComponent from "./PaypalButton";


const Checkout = (address) => {
  const { language } = useContext(LanguageContext);
  const [newAddress, setNewAddress] = useState({
    fullname: '',
    mobile: '',
    alternatePhone: '',
    emailid: '',
    address: '',
    addressLocation: '',
    city: '',
    state: '',
    zipcode: '',
    addNotes: '',
  });
  const [addresses, setAddresses] = useState([]);
  // const [editAddresses, setEditAddresses] = useState([formData]);
  // const [formData, setFormData] = useState(currentAddress);

  const [expanded, setExpanded] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [customsInfo, setCustomsInfo] = useState("Provided");
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeBooks, setActiveBooks] = useState([]);
  const [bookCounts, setBookCounts] = useState({});
  const [buybookCounts, setBuyBookCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { user } = useContext(AuthContext);
  const [orderBook, setOrderBook] = useState(null);
  const [usedAddress, setUsedAddress] = useState(null);
  const [isEditingAddr, setIsEditingAddr] = useState(null);  
  const [load, setLoad] = useState(false)
  const [finalCartTotal, setFinalCartTotal] = useState(0)
  const [finalBuyNowTotal, setFinalBuyNowTotal] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState("paypal");
  const [payoutRecipient, setPayoutRecipient] = useState("");
  const [openNote, setOpenNote]=useState(false)
  const [isBuyNow, setIsBuyNow] = useState(false);

  const userId = user?.id;
  const addressId = user?.id;
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errors, setErrors] = useState({});
  const [mobileInput, setMobileInput] = useState("");
  const [mobileError, setMobileError] = useState("");
  const { incrementCartCount, decrementCartCount } = useContext(CartContext);

  //console.log(newAddress, "adad");

  

  const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
    "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
    "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
    "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
    "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
    "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota",
    "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
    "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia",
    "Washington", "West Virginia", "Wisconsin", "Wyoming"
  ];

  const location = useLocation();
  const bookId = location.state?.bookId; // Safely access the bookId
  useEffect(() => {
    if (addresses.length > 0 && !selectedAddress) {
      setSelectedAddress(addresses[0]);
      setUsedAddress(addresses[0]);
    }    
  }, [addresses, selectedAddress]);

  useEffect(() => {
    if (usedAddress) {    
      setExpanded("panel2");
    }else{
      setExpanded("panel1");
    }
  }, [addresses]);

 const writeNotes =()=>{
  setOpenNote(true)
 }
 const handleCloseNotes =()=>{
  setOpenNote(false)
 }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleUseAddress = (step) => {
    setExpanded(step);
    setUsedAddress(selectedAddress)
  };

  const handleClose = () => {
    setOpen(false);
  };


  const updateCartInBackend = async (bookId, newQuantity) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/purchase/updateCartQuantity",
        {
          userId: userId,
          bookId,
          newQuantity,
        }
      );

      if (response.status === 200) {
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
        cartData.forEach((item) => {
          counts[item.book_id] = item.quantity;
          total += item.price * item.quantity;
        });
        setBookCounts(counts);                
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); 
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount); 
        setTotalPriceWithTax(response.data.finalTotal);
        setDiscount(response.data.discount);
      }
    } catch (error) {
      //console.error("Error updating cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = async (bookId, delta) => {        
  
    setLoading(true);

    if (isBuyNow) {      
      const newCount = Math.max((bookCounts[bookId] || 0) + delta, 0);
  
      setBookCounts((prevCounts) => ({
        ...prevCounts,
        [bookId]: newCount,
      }));
            
      updateOrderInBackend(bookId, newCount)
        .then(() => {      
        })
        .catch((error) => {
          //console.error("Error updating Buy Now order:", error);
        })
        .finally(() => {
          setLoading(false);
        });
  
    } else {  
    setBookCounts((prevCounts) => {
      const currentCount = prevCounts[bookId] || 0;
      const newCount = Math.max(currentCount + delta, 0);
      
      const updatedCounts = {
        ...prevCounts,
        [bookId]: newCount,
      };
  
      updateCartInBackend(bookId, newCount)
        .then(() => {      
        })
        .catch((error) => {
          //console.error("Error updating cart in backend:", error);          
          setBookCounts(prev => ({
            ...prev,
            [bookId]: currentCount
          }));
        })
        .finally(() => {
          setLoading(false);
        });
  
      return updatedCounts;
    });
    
    if (delta > 0) {
      incrementCartCount(1);
    } else if (delta < 0) {
      decrementCartCount(1);
    }
  }
  };
  

  const handleDelete = async (bookId) => {
    setLoading(true);
    try {      
      let response;
      if (isBuyNow) {        
        
        response = await axiosInstance.delete(`/purchase/deleteFromBuyNowOrder/${userId}/${bookId}`);        
        window.location.href = "/addcart";
        // setIsBuyNow(false);
        
      } else {                
        response = await axiosInstance.delete(`/purchase/deleteFromCart/${userId}/${bookId}`);
        decrementCartCount(bookCounts[bookId]);  
      }
  
      if (response.status === 200) {
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
  
        cartData.forEach((item) => {
          counts[item.book_id] = item.quantity;
          total += item.price * item.quantity;
        });
  
        setBookCounts(counts);                
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); 
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount); 
        setTotalPriceWithTax(response.data.finalTotal);
        setDiscount(response.data.discount || 0);        
      }
    } catch (error) {
      //console.error("Error removing book from cart:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleConfirmDelete = () => {
    if (bookToDelete !== null) {
      handleDelete(bookToDelete); 
      handleClose(); 
    }
  };


  const updateOrderInBackend = async (bookId, newCount) => {
    
    try {
      const response_1 = await axiosInstance.post(`/purchase/updateOrder/${userId}`, {
        bookId,
        quantity: newCount,
      });     
      const cartData = response_1.data.books;
      const counts = {};
      let total = 0;
      cartData.forEach((item) => {
        counts[item.book_id] = item.quantity;
        total += item.price * item.quantity;
      });

      //console.log(cartData, "dddcscs");

      setBookCounts(counts);
      setTotalPrice(response_1.data.totalPrice);
      setActiveBooks(cartData); 
      setShippingCharge(response_1.data.shippingCharge);
      setTaxAmount(response_1.data.taxAmount);
      setTotalPriceWithTax(response_1.data.finalTotal); 
      setDiscount(response_1.data.discount);
      setFinalBuyNowTotal(response_1.data.finalTotal)      
      setIsBuyNow(true);
    } catch (error) {
      //console.log(error);
      
      //console.error("Error updating order:", error);
    }
  };

  const fetchActiveBooks = async () => {
    try {
      if (bookId) {
        // If bookId is present, fetch the specific book for Buy Now
        const response = await axiosInstance.get(
          `/books/book/${bookId}`
        );
        const response_1 = await axiosInstance.get(
          `/purchase/getBuyCartBooks/${bookId}/${userId}`
        );        
        const cartData = response_1.data.books;
        const counts = {};
        let total = 0;
        cartData.forEach((item) => {
          counts[item.book_id] = item.quantity;
          total += item.price * item.quantity;
        });

        //console.log(cartData, "ssssss");
        
        setBookCounts(counts);
        setTotalPrice(response_1.data.totalPrice);
        setShippingCharge(response_1.data.shippingCharge);
        setTaxAmount(response_1.data.taxAmount); 
        setTotalPriceWithTax(response_1.data.finalTotal); 
        setDiscount(response_1.data.discount);                
        setOrderBook(response.data);
        setIsBuyNow(true);
      } else {
        const response = await axiosInstance.get(
          `/purchase/getActiveCartBooks/${userId}`
        );
        
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
        cartData.forEach((item) => {
          counts[item.book_id] = item.quantity;
          total += item.price * item.quantity;
        });

        setBookCounts(counts);
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); // Set the fetched books in state
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount); // Set the tax amount
        setTotalPriceWithTax(response.data.finalTotal); // Total price after tax
        setDiscount(response.data.discount);
        setIsBuyNow(false);
      }
    } catch (error) {
      //console.error("Error fetching active books:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveBooks();
  }, [bookId, userId, language]);

  useEffect(() => {    
    if (!loading && !activeBooks.length && !orderBook) {
      window.location.href = "/addcart";
    }
  }, [loading, activeBooks, orderBook, navigate]);  

  const handleClickOpen = (bookId) => {
    setBookToDelete(bookId);
    setOpen(true); 
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewAddress({
      fullname: "",
      mobile: "",
      emailid: "",
      zipcode: "",
      addressLocation: "",
      city: "",
      address: "",
      state: "",
      alternatePhone: "",
      addNotes:''
    });
  };

  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateField = (name, value, mobile, emailid, zipcode) => {
    let errorMessage = '';

    if (!value.trim()) {
      errorMessage = `${name} is required`;
      errorMessage = `${mobile} is required`;
      errorMessage = `${emailid} is required`;
      errorMessage = `${zipcode} is required`;
    }

    // Update errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };


  const validateName = (e) => {
    e.target.value = e.target.value.replace(/\D/g, "");
  }


  const handleAddAddress = async (e) => {
    
    e.preventDefault()
    setLoad(true)
    try {
      const response = await axiosInstance.post(
        `/users/${userId}/address`,
        newAddress
      );
      
      if (response.status === 200) {
        setAddresses((prevAddresses) => [...prevAddresses, response.data.address]);
        setSelectedAddress(response.data.address); 
        handleCloseDialog(false); 
      }
    } catch (error) {
      //console.error("Error adding address:", error);
    } finally {
      setLoad(false)
      setOpenDialog(false);
    }
  };

  const fetchAddresses = async () => {
    const response = await axiosInstance.get(`/users/${userId}/address`)
    setAddresses(response.data);
  };

  useEffect(() => {
    fetchAddresses();    
  }, [userId]);

const handleUpdateAddress = async (addId) => {  
  
  try {
    const response = await axiosInstance.put(
      `/users/address/${addId}`,
      newAddress
    );

    // Update the addresses in state without re-fetching
    setAddresses((prevAddresses) =>
      prevAddresses.map((address) =>
        address.id === addId ? response.data.address : address
      )
    );

    
    setOpenDialog(false);
  } catch (error) {
    //console.error("Error updating address:", error);
  }
};


  const handleEditAddress = async (addId) => {
    setOpenDialog(true);
    try {
      const response = await axiosInstance.get(`/users/getaddress/${addId}`);
      setIsEditingAddr(addId);
      const address = response.data[0];
      setNewAddress({
        fullname: address.name,
        mobile: address.mobile_number,
        emailid: address.email_id,
        zipcode: address.zipcode,
        addressLocation: address.address_line_2,
        city: address.city,
        address: address.address_line_1,
        state: address.state,
        alternatePhone: address.alternate_phone,
        addNotes:address.notes     
      });
      return response.data;
    } catch (error) {
      //console.error("Error fetching address:", error);
    } finally {
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddressChange = (event) => {    
    const selected = addresses.find(
      (addr) => addr.id === parseInt(event.target.value)
    );
    if (selected) {
      setSelectedAddress(selected);
    }
  };

  const handleApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      //console.log("Transaction completed by ", details.payer.name.given_name);      
      const res = await axiosInstance.post("/payment/paypalpurchase", {
        userId,
        amount: totalPriceWithTax,
        transactionDetails: details,
      });

      setSuccess(true);
      //console.log("Payment Success:", res.data);
    } catch (err) {
      //console.error("Error capturing payment:", err);
      setError("Payment could not be processed");
    }
  };
  // PayPal Integration for "Buy Now"
  // const renderBuyNowPayPalButtons = () => (
  //   <PayPalButtons
  //     style={{ layout: "vertical" }}
  //     createOrder={(data, actions) => {
  //       return actions.order.create({
  //         purchase_units: [
  //           {
  //             amount: {
  //               value: totalPriceWithTax, 
  //             },
  //           },
  //         ],
  //       });
  //     }}
  //     onApprove={handleApprove}
  //     onError={(err) => {
  //       //console.error("PayPal Error:", err);
  //       setError(`Payment could not be processed: ${err.message || "Unknown error"}`);
  //     }}
  //   />
  // );

  // const renderCartPayPalButtons = () => (
  //   <PayPalButtons
  //     style={{ layout: "vertical" }}
  //     createOrder={(data, actions) => {
  //       return actions.order.create({
  //         purchase_units: [
  //           {
  //             amount: {
  //               value: totalPriceWithTax,
  //             },
  //           },
  //         ],
  //       });
  //     }}
  //     onApprove={handleApprove}
  //     onError={(err) => {
  //       //console.error("PayPal Error:", err);
  //       setError(`Payment could not be processed: ${err.message || "Unknown error"}`);
  //     }}
  //   />
  // );

  const handleError = (err) => {
    //console.error("Error with PayPal:", err);
    setError("Payment could not be processed");
  };

  const handlePaymentSelection = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePayout = async () => {
    if (!payoutRecipient || !totalPriceWithTax) {
      alert("Please enter recipient and amount");
      return;
    }

    try {
      const res = await axiosInstance.post("/payment/payouts", {
        recipient_type: paymentMethod === "paypal" ? "EMAIL" : "PHONE",
        recipient: payoutRecipient,
        amount: totalPriceWithTax,
      });
      alert(res.data.message);
    } catch (error) {
      //console.error("Error processing payout:", error);
      alert("Payout failed");
    }
  };

  const handleNextStep = (step) => {
    setExpanded(step);
  };

  const handleChangeClick = (step) => {
    setExpanded(step);
  };

  const handleCloseClick = () => {
    setExpanded(false);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <Box
      sx={{
        margin: "100px 40px 80px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >

      {loading && (
        <PurchaseLoading/>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <IconButton
            onClick={goBack}
            sx={{
              position: "absolute",
              left: { xs: 30, md: 10 },
              top: { xs: 70, md: 80 },
              color: "black",
              bgcolor: "#fff",
              "&:hover": { bgcolor: "darkgreen", color: "#fff" },
              ml: 2,
            }}
            aria-label="Go Back"
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Box
            sx={{
              bgcolor: "#ffffff",
              maxWidth: 600,
              margin: "auto",
              border: '1px solid #B6B3B2',
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                pb: "20px",                
              }}
            >
              Checkout
            </Typography>

            {/* Shipping Address */}

            <Address
              expanded={expanded}
              handleChange={handleChange}
              usedAddress={usedAddress}
              handleCloseClick={handleCloseClick}
              selectedAddress={selectedAddress}
              addresses={addresses}                                        
              handleAddressChange={handleAddressChange}
              handleEditAddress={handleEditAddress}
              handleOpenDialog={handleOpenDialog}
              handleUseAddress={handleUseAddress}
            />

           
            <Accordion
              expanded={expanded === "panel2"}
              // onChange={handleChange("panel2")}
              sx={{ border: "none", boxShadow: "none" }}
            >
              <AccordionSummary>
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      2. Order Summary
                    </Typography>
                  </Grid>                  

                  <Grid item>
                    {expanded === "panel3" ? (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ color: "#FF4E33", marginLeft: "auto" }}
                        // onClick={handleChange("panel2")}
                        onClick={() => handleUseAddress("panel2")}
                      >
                        Change
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>

              <Grid container>
                {isBuyNow ? (                  
                  <Grid item xs={12} key={orderBook.book_id}>
                    <ShoppingCartItem
                      book={orderBook}
                      handleIncrement={() => handleQuantityChange(orderBook.book_id, 1)}
                      handleDecrement={() => handleQuantityChange(orderBook.book_id, -1)}
                      handleDelete={() => handleClickOpen(orderBook.book_id)}                        
                      bookCounts={bookCounts}   
                      p={2}
                      m={5}
                    />
                  </Grid>
                ) : (                  
                  activeBooks.map((book, index) => (
                    <Grid item xs={12} key={book.book_id}>
                      <ShoppingCartItem
                        book={book}
                        handleIncrement={() => handleQuantityChange(book.book_id, 1)}
                        handleDecrement={() => handleQuantityChange(book.book_id, -1)}
                        handleDelete={() => handleClickOpen(book.book_id)}                        
                        bookCounts={bookCounts}   
                        p={2}
                        m={5}
                      />
                      {index < activeBooks.length - 1 && <Divider />}{" "}                      
                    </Grid>
                  ))
                )}

                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{
                      height: "30px",
                      width: "140px",
                      backgroundColor: "#FF4E33",
                      color: "white",
                      "&:hover": { backgroundColor: "#FF8C00" },
                      borderRadius: "20px",
                    }}
                    onClick={() => handleNextStep("panel3")}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Accordion>
            
            <Accordion
              expanded={expanded === "panel3"}
              sx={{ border: "none", boxShadow: "none" }}
            >
              <AccordionSummary>
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      3. Payment
                    </Typography>
                  </Grid>

                  {/* <Grid item>
                    {expanded === "panel3" ? (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ color: "#FF4E33", marginLeft: "auto" }}
                        onClick={handleCloseClick}
                      >
                        Close
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ color: "#FF4E33", marginLeft: "auto" }}
                        onClick={handleChange("panel3")}
                      >
                        Change
                      </Button>
                    )}
                  </Grid> */}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {/* <Typography variant="body1">
                  Here are the items and shipping details.
                </Typography> */}
                {/* <Button variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
                        Confirm & Place Order
                    </Button> */}
                {/* "client-id": "AcgvnHRPQl-UgJ5_PJxpTYtIlaWbUQijaRJp9TzvHewYwt3cW2i-t3N0OD5PrdSXwR4_Yc6cKqbyhCu_",  */}
                <PayPalScriptProvider
                  options={{
                    "client-id": "AcgvnHRPQl-UgJ5_PJxpTYtIlaWbUQijaRJp9TzvHewYwt3cW2i-t3N0OD5PrdSXwR4_Yc6cKqbyhCu_",
                    currency: "USD",
                  }}>

                  {success ? (
                    <h3>Payment Successful!</h3>
                  ) : (                    
                      <Box>                           
                        {totalPriceWithTax > 0 ?                       
                          <PayPalButtonComponent
                            totalPriceWithTax={totalPriceWithTax}
                            onApprove={handleApprove}
                            onError={(err) => {
                              //console.error("PayPal Error:", err);
                              setError(`Payment could not be processed: ${err.message || "Unknown error"}`);
                          }}
                          /> :
                          <p style={{ color: "red" }}>Invalid total amount. Please try again.</p>}
                      </Box>           
                    )}
                </PayPalScriptProvider>
              </AccordionDetails>
            </Accordion>            
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <OrderSummary
              totalPrice={totalPrice}
              discount={discount}
              shippingCharge={shippingCharge}
              taxAmount={taxAmount}
              totalPriceWithTax={totalPriceWithTax}            
              showButton={false}
            />

          <Box pt={2} display="flex" justifyContent="center" alignItems="center">
            <Box component="img" sx={{width:25, height:30}} src={secureLogo}/>
            <Typography pl="8px" sx={{'&:hover': { 
        cursor: "default" 
            }}}>Safe and Secure Payments.</Typography>
          </Box>
          
        </Grid>                      
      </Grid>


      <Dialog open={openDialog} onClose={handleCloseDialog}>        
        <DialogTitle  fontSize={'30px'} fontWeight={'bold'}>Add address</DialogTitle>
        <form onSubmit={(e)=>handleAddAddress(e)} >
          <DialogContent >
            <Grid container spacing={3} >
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Full name (First & Last name)"
                  variant="outlined"
                  name="fullname"
                  value={newAddress.fullname}
                  onChange={handleNewAddressChange}                  
                  required
                  inputProps={{ minLength: 1, maxLength: 50 }} 
                  size="small"                  
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",                      
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                  fullWidth
                  label="Mobile number"
                  variant="outlined"
                  name="mobile"            
                  size="small"                        
                  inputProps={{ minLength: 10, maxLength: 10 }} 
                  onChange={handleNewAddressChange }
                  value={mobileInput+newAddress.mobile}
                  onInput={validateName}  
                  type="tel"
                  maxLength="10"
                  autoComplete="true"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',                      
                    },
                    '& .MuiInputLabel-root': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField                 
                  fullWidth
                  label="Alternate Phone (Optional)"
                  variant="outlined"
                  name="alternatePhone"
                  value={newAddress.alternatePhone}
                  onChange={handleNewAddressChange}
                  onInput={validateName}    
                  size="small"                                                        
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",                      
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Email ID"
                  variant="outlined"
                  name="emailid"
                  type="email" 
                  size="small"                  
                  required
                  value={newAddress.emailid}
                  onChange={handleNewAddressChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",                      
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!errors.address}
                  helperText={errors.address}
                  fullWidth
                  label="Street address or P.O.Box"
                  variant="outlined"
                  name="address"   
                  size="small"                                 
                  inputProps={{ minLength: 1, maxLength: 40 }}                    
                  value={newAddress.address}
                  onChange={handleNewAddressChange}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Apt, suite, unit, building, floor, etc."
                  variant="outlined"
                  name="addressLocation"
                  inputProps={{ minLength: 1, maxLength: 40 }}                                      
                   value={newAddress.addressLocation}
                    onChange={handleNewAddressChange}
                  required
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="City"
                  variant="outlined"
                  name="city"
                  value={newAddress.city}
                  onChange={handleNewAddressChange}
                  size="small"
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",                      
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  required
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",                      
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                >
                  <InputLabel>State</InputLabel>
                  <Select
                    label="State"
                    value={newAddress.state}
                    name="state"
                    onChange={handleNewAddressChange}
                  >
                    {states.map((state, index) => (
                      <MenuItem key={index} value={state}>{state}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  error={!!errors.zipcode}
                  helperText={errors.zipcode}
                  fullWidth
                  label="ZIP Code"
                  variant="outlined"
                  name="zipcode"
                  onInput={(e) => validateName(e)}
                  inputProps={{ maxLength: 10 }}
                  value={newAddress.zipcode}
                  onChange={handleNewAddressChange}
                  size="small"
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",                      
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography sx={{cursor:'pointer'}} >Add notes </Typography>
                 <TextField
                 name="addNotes"
                 size="small"                  
                  value={newAddress.addNotes}
                  onChange={handleNewAddressChange}
                 sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",                      
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}/>
                  <Dialog open={openNote} border={'none'}>

                  <DialogActions>
                    <Button onClick={handleCloseNotes} color="secondary">Cancel</Button>
                    <Button>Submit</Button>
                  </DialogActions>
                  </Dialog>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary">
                Cancel
              </Button>
              {isEditingAddr ? (
                <Button
                  onClick={() => handleUpdateAddress(isEditingAddr)}
                  color="primary"
                  variant="contained"
                  sx={{
                    height: "30px",
                    width: "auto",
                    backgroundColor: "#FF4E33",
                    color: "white",
                    "&:hover": { backgroundColor: "#FF8C00" },
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button                  
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{
                    height: "30px",
                    width: "150px",
                    backgroundColor: "#FF4E33",
                    color: "white",
                    "&:hover": { backgroundColor: "#FF8C00" },
                  }}
                >
                  {load ? <Box><CircularProgress size={15} sx={{ color: "#ffffff" }} /></Box> : "Add Address"}
                </Button>
              )}
            </DialogActions>
          </DialogContent>

        </form>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this book?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="secondary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Checkout;
