import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Paper,
    TextField,
    IconButton,
    Box,
    Button,
    Typography,
    Snackbar,
    CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import axiosInstance from '../utils/AxiosInstance';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ItemsDialog = ({ open, onClose, onImageSelect, setEmpty, userId }) => {
    const [images, setImages] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    // const [uploadedImages, setUploadedImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);    
    const [userImages, setUserImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false); 
    const [uploadComplete, setUploadComplete] = useState(false);
    
//console.log(selectedImage,"shah");

    const fetchImages = async (query = '') => {        
        
        const response = await fetch(
            `https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API_KEY}&q=${encodeURIComponent(query)}&image_type=photo&per_page=20&safesearch=true`
        );
        const data = await response.json();
        setImages(data.hits);
    };

    const fetchUserImages = async () => {
        try {
            const response = await axiosInstance.get(`/create/get-user-images/${userId}`);
            //console.log(response.data.images, "ssss");
            setUserImages(response.data.images);
        } catch (error) {
            //console.error('Error fetching user images:', error);
        }
    };    

    useEffect(() => {
        fetchImages();
        fetchUserImages();
    }, []);

    const handleSearch = () => {
        fetchImages(searchTerm);

    };

    const uploadImage = async (file) => {
        setLoading(true); 
        setSnackbarOpen(true);
        setUploadComplete(false);

        const formData = new FormData();
        formData.append('image', file);
        formData.append('userId', userId);

        try {
            const response = await axiosInstance.post('/upload/image-content', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            const { url } = response.data;
            onImageSelect(url);
            fetchUserImages();
            setUploadComplete(true);            
            onClose();
            setTimeout(() => setSnackbarOpen(false), 3000);
        } catch (error) {
            //console.error('Error uploading image:', error);
        }finally {
            setLoading(false); 
        }
    };

    const uploadPixabayImage = async (imageUrl) => {
        setLoading(true);
        setSnackbarOpen(true);
        setUploadComplete(false);

        try {
            const response = await axiosInstance.post('/upload/image-content', {
                userId,
                imageUrl,
            });
            const { url } = response.data;
            onImageSelect(url);
            fetchUserImages();
            setUploadComplete(true);            
            onClose();
            setTimeout(() => setSnackbarOpen(false), 3000);
        } catch (error) {
            //console.error('Error uploading Pixabay image:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            const file = files[0];
            uploadImage(file);
            // onClose();
        }
    };

    const handleImageSelect = (url, isPixabay = false) => {
        if (isPixabay) {            
            uploadPixabayImage(url);
        } else {
            setSelectedImage(url);
            onImageSelect(url);
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Images</DialogTitle>
            <DialogContent>
                <Box sx={{ marginBottom: '20px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="image-upload"
                        />
                        <label htmlFor="image-upload">
                            <IconButton disableRipple component="span" color="primary">
                                <UploadIcon style={{ marginRight: '8px' }} />
                                <span>Upload Images</span>
                            </IconButton>
                        </label>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search"
                        />
                        <IconButton onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </Box>

                {searchTerm === '' && (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Recently Used
                        </Typography>
                        {userImages.length > 0 ? (
                        <Grid container spacing={2}>                        
                            {userImages.map((image) => (
                                <Grid item xs={3} key={image.id}>
                                    <Paper
                                        sx={{
                                            padding: '10px',
                                            cursor: 'pointer',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                            height: '150px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={() => handleImageSelect(image.url)}
                                    >
                                        <img
                                            src={image.url}
                                            alt={image.name || 'Recent Image'}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Paper>
                                </Grid>
                            ))}                                                                                                                                
                        </Grid>
                         ) : ( 
                            <Grid container spacing={2} justifyContent="center">
                                <Typography pt={3}>No recent images found.</Typography>                            
                            </Grid>  
                         )}
                    </>
                )}
                
                <Typography variant="h6" gutterBottom sx={{ marginTop: searchTerm ? '0' : '20px' }}>
                    {searchTerm ? 'Search Results' : 'Popular Images'}
                </Typography>
                <Grid container spacing={2}>
                    {images.map((image) => (
                        <Grid item xs={3} key={image.id}>
                            <Paper
                                sx={{
                                    padding: '10px',
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    height: '150px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={() => handleImageSelect(image.webformatURL, true)}
                            >
                                <img
                                    src={image.webformatURL}
                                    alt={image.tags}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#FF0004',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#D00000',
                            },
                        }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Box>  
            </DialogContent>

            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {loading && !uploadComplete ? (
                            <CircularProgress size={20} sx={{ mr: 1 }} />
                        ) : (
                            <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                        )}
                        <span>{uploadComplete ? 'Upload Complete' : 'Uploading Image...'}</span>
                    </Box>
                }
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            />
        </Dialog>
    );
};

export default ItemsDialog;
