import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function loader() {
  return (
    <Box sx={{ display: 'flex',justifyContent:'center' ,ml:'48.5%'}}>
      <CircularProgress />
    </Box>
  );
}