import React, { createContext, useState, useEffect, useContext } from 'react';
import axiosInstance from '../../components/utils/AxiosInstance';
import { AuthContext } from './AuthContext';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const { isAuthenticated, user, logout, checkAuth } = useContext(AuthContext);

  const userId = user?.id;

  useEffect(() => {
    if (isAuthenticated && userId) { 
    const fetchInitialCartCount = async () => {
      try {
        const response = await axiosInstance.get(`/purchase/getCartCount/${userId}`);
        setCartCount(response.data.count); 
      } catch (error) {
        //console.error('Error fetching cart count:', error);
      }
    };

    fetchInitialCartCount();
    }
  }, [isAuthenticated, userId]);


  const incrementCartCount = (count) => {
    setCartCount((prevCount) => prevCount + count);
  };

  const decrementCartCount = (count) => {
    setCartCount((prevCount) => Math.max(0, prevCount - count));
  };

  const resetCartCount = () => {
    setCartCount(0);
  };

  return (
    <CartContext.Provider value={{ cartCount, incrementCartCount, decrementCartCount, resetCartCount }}>
      {children}
    </CartContext.Provider>
  );
};
