import { Dialog, DialogContent, DialogTitle, Grid, Paper, Typography, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';

const preloadImages = (images) => {
  return Promise.all(
    images.map((imageSrc) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => resolve(imageSrc);
      });
    })
  );
};

const PageSelectorGrid = ({ open, onClose, pages, onGridClick }) => {
  const [loading, setLoading] = useState(true);
  const paperWidth = 200;
  const paperHeight = 250;

  useEffect(() => {
    if (open && pages.length > 0) {
      // Preload all the page images before showing the dialog
      setLoading(true);
      const imageSources = pages.map((page) => page.image);
      preloadImages(imageSources).then(() => {
        setLoading(false); 
      });
    }
  }, [open, pages]);

  const handlePageClick = (page) => {
    onGridClick(page);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          height: '90vh',
        },
      }}
    >
      <DialogTitle>Page View</DialogTitle>
      <DialogContent>
        {loading ? (
         
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={3}>
            {Array.isArray(pages) && pages.length > 0 ? (
              pages.map((page, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Paper
                    sx={{
                      position: 'relative',
                      // padding: '20px',
                      border: '1px solid #ddd',
                      cursor: 'pointer',
                      width: `${paperWidth}px`,
                      height: `${paperHeight}px`,
                      boxSizing: 'border-box',
                      overflow: 'hidden',
                      backgroundColor: '#fff',
                    }}
                    onClick={() => handlePageClick(page)}
                  >
                    <img
                      src={page.image}
                      alt={`Page ${page.pageNumber}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Paper>
                  <Typography variant="caption" sx={{ textAlign: 'center', marginTop: '5px' }}>
                    {`Page ${page.pageNumber}`}
                  </Typography>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" sx={{ textAlign: 'center', width: '100%' }}>
                No pages available.
              </Typography>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PageSelectorGrid;
