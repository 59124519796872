import React, { useState, useEffect } from 'react';
import {
    Box, Button, Dialog, DialogContent, Typography, TextField,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, Paper, InputAdornment, IconButton, TableSortLabel, CircularProgress, DialogActions, DialogTitle
} from '@mui/material';
import { Search, Delete } from '@mui/icons-material';
import axiosInstance from '../utils/AxiosInstance';

const AddSchool = () => {
    const [users, setUsers] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [schoolName, setSchoolName] = useState('');
    const [addUser, setAddUser] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchTerm, setSearchTerm] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(null);
    const [loading, setLoading] = useState(true);
    const [parentDialogOpen, setParentDialogOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [selectedUser,setSelectedUser] =useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarKey, setSnackbarKey] = useState(0);

    //console.log("sss",selectedUser);
    

    // Fetch school data
    const fetchSchools = async () => {
        try {
            const response = await axiosInstance.get('/schools/getSchools');
            setUsers(response.data);
        } catch (error) {
            //console.error("Error fetching schools data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSchools();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAddUser(true);

        try {
            const response = await axiosInstance.post('/admin/add-school', { school_name: schoolName });
            setSuccessMessage('School Added Successfully');
            setDialogOpen(true);  // Open success dialog
            setSchoolName('');
            fetchSchools(); // Refresh the school list
        } catch (error) {
            //console.error('Error adding school', error);
        } finally {
            setAddUser(false);
            setParentDialogOpen(false); // Close the Add School form dialog
        }
    };

    const handleDialogOpen = () => {
        setParentDialogOpen(true);
    };

    const handleDialogClose = () => {
        setParentDialogOpen(false);
    };

    const handleSuccessDialogClose = () => {
        setDialogOpen(false);  // Close success dialog
    };

    const handleSearchChange = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchTerm(searchValue);
        setUsers(prevUsers => prevUsers.filter(user => user.school_name.toLowerCase().includes(searchValue)));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
      };
    
      const sortedRowInformation = (rows, comparator) => {
        const stabilizedRows = rows.map((el, index) => [el, index]);
        stabilizedRows.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedRows.map((el) => el[0]);
      };
      const getComparator = (order, orderBy) => {
        return order === "desc"
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      };
    
      const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      };

      const handleOpenDeleteDialog =(id)=>{
        setSelectedUser(id);
        setOpenDeleteDialog(true)
        // setOpenDeleteDialog(false)
       }
        useEffect(() => {    
            fetchSchools()
        }, []);
      
        const handleDeleteUser = async (id) => {
          //console.log(id);
      
          
            try {
              const response = await axiosInstance.delete(`/admin/delete-school/${id}`);
      
              if (response.status === 200) {
                setSnackbarMessage('deleted successfully!');
                setSnackbarKey(prev => prev + 1);
                setSnackbarOpen(true);
                fetchSchools()
              } else {
                alert('Failed to delete the user');
              }
            } catch (error) {
              //console.error("Error deleting user:", error);
              if (error.response.status === 401) {
                window.location.href = '/auth/login';
              }
              else if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message);
              }              
              else {
                alert("Server error while deleting the user");
              }
            }finally{
              setOpenDeleteDialog(false)
            }
        };

        const handleCloseDeleteDialog=()=>{
            setOpenDeleteDialog(false)
           
           }

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                    Add School
                </Button>
                <TextField
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976d2' }}>
                            <TableCell sx={{ color: '#fff' }}>S.No</TableCell>
                            <TableCell sx={{ color: '#fff' }}>                   
                                <TableSortLabel
                                    active={orderBy === "school_name"}
                                    direction={orderBy === "school_name" ? order : "asc"}
                                    onClick={() => handleRequestSort("school_name")}
                                    hideSortIcon={false}
                                    sx={{
                                    color: orderBy === "school_name" ? "#000" : "#fff",
                                    "& .MuiTableSortLabel-icon": {
                                        opacity: 1,                          
                                    },           
                                    "&.MuiTableSortLabel-root": {
                                        color: "#fff",
                                    },
                                    "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                                        color: "#000", 
                                    },
                                    "&:hover": {
                                        color: "#f0f0f0", 
                                        "& .MuiTableSortLabel-icon": {
                                        color: "#f0f0f0", 
                                        },
                                    }           
                                    }}
                                >
                                  School Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{color:'#fff'}}>Action</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRowInformation(users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),getComparator(order, orderBy)).map((user, index) => (
                            <TableRow key={user.id}>
                                <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                <TableCell>{user.school_name}</TableCell>
                                <TableCell>
                                    <IconButton onClick={()=>handleOpenDeleteDialog(user.id)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            <Dialog open={parentDialogOpen} onClose={handleDialogClose} disableScrollLock={true}>
                <DialogContent>
                    <Typography variant="h4" align="center" fontWeight="bold" color="#000">Add School</Typography>

                    <form onSubmit={handleSubmit}>
                        <TextField
                            required
                            autoFocus
                            label="School Name"
                            fullWidth
                            margin="normal"
                            value={schoolName}
                            onChange={(e) => setSchoolName(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': { borderRadius: '10px', height: "50px" },
                                '& .MuiInputLabel-root': { color: 'black' },
                            }}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            color="primary"
                            sx={{
                                mt: "20px",
                                color: '#ffffff',
                                backgroundColor: "#1976d2",
                                '&:hover': { backgroundColor: '#1976d2', borderColor: '#1976d2' },
                            }}
                        >
                            {addUser ? <Box><CircularProgress size={15} sx={{ color: "#ffffff" }} /></Box> : 'Add School'}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Success Message Dialog */}
            <Dialog open={dialogOpen} onClose={handleSuccessDialogClose} aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{successMessage}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleSuccessDialogClose}>Ok</Button>
                </DialogActions>
            </Dialog>

            <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this school?"}
          </DialogTitle>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDeleteDialog}>
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteUser(selectedUser)}
              color="secondary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        </>
    );
};

export default AddSchool;
