import React, { useState, useEffect } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
  Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TablePagination, Paper, InputAdornment, IconButton,
  TableSortLabel
} from '@mui/material';
import { Search, Delete } from '@mui/icons-material';
import axiosInstance from '../utils/AxiosInstance';
import AdminRegistraion from "../authentication/AdminRegistraion"

const AdminUserControl = () => {
  const [users, setUsers] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: ''
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parentDialogOpen, setParentDialogOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedUser,setSelectedUser] =useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
 


  const fetchAdminUsers = async () => {
    try {
      const response = await axiosInstance.get('/admin/admin-users');
      setUsers(response.data);
      setAllUsers(response.data);
      //console.log(response.data);
      
    } catch (err) {
      //console.error('Error fetching users:', err);
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      setError('Error fetching users');
    } finally {
      setLoading(false);
    }
  };
 const handleCloseDeleteDialog=()=>{
  setOpenDeleteDialog(false)
 
 }
 const handleOpenDeleteDialog =(id)=>{
  setSelectedUser(id);
  setOpenDeleteDialog(true)
  // setOpenDeleteDialog(false)
 }
  useEffect(() => {    
    fetchAdminUsers();
  }, []);

  const handleDeleteUser = async (id) => {
    //console.log(id);

    
      try {
        const response = await axiosInstance.delete(`/admin/delete-user/${id}`);

        if (response.status === 200) {
          setSnackbarMessage('User deleted');
          setSnackbarKey(prev => prev + 1);
          setSnackbarOpen(true);
          fetchAdminUsers();
        } else {
          alert('Failed to delete the user');
        }
      } catch (error) {
        //console.error("Error deleting user:", error);
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }
        else if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        }              
        else {
          alert("Server error while deleting the user");
        }
      }finally{
        setOpenDeleteDialog(false)
      }
  };

  useEffect(() => {
    const isFormValid = Object.values(userDetails).every(value => value.trim() !== '');
    setIsSubmitDisabled(!isFormValid);
  }, [userDetails]);
 
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleDialogOpen = () => {
    setParentDialogOpen(true);
  };

  const handleDialogClose = () => {
    setParentDialogOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = () => {
    setUsers([...users, userDetails]);
    setUserDetails({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: ''
    });
    handleDialogClose();
    setSnackbarMessage('User added');
    setSnackbarKey(prev => prev + 1);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const filteredUsers = allUsers.filter((user) =>
        user.first_name.toLowerCase().includes(searchValue) ||
        user.last_name.toLowerCase().includes(searchValue)
    );

    setUsers(filteredUsers);
};

const handleRequestSort = (property) => {
  const isAsc = orderBy === property && order === "asc";
  setOrder(isAsc ? "desc" : "asc");
  setOrderBy(property);
};

const sortedRowInformation = (rows, comparator) => {
  const stabilizedRows = rows.map((el, index) => [el, index]);
  stabilizedRows.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedRows.map((el) => el[0]);
};
const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};



  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialogOpen}
        >
          Add Users
        </Button>
        <TextField
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor:'#1976d2'}}>
              <TableCell sx={{color:'#fff'}}>S.No</TableCell>
              <TableCell sx={{color:'#fff'}}>
                <TableSortLabel
                        active={orderBy === "first_name"}
                        direction={orderBy === "first_name" ? order : "asc"}
                        onClick={() => handleRequestSort("first_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "first_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                First Name
                </TableSortLabel>
                </TableCell>
              <TableCell sx={{color:'#fff'}}>
                  <TableSortLabel
                        active={orderBy === "last_name"}
                        direction={orderBy === "last_name" ? order : "asc"}
                        onClick={() => handleRequestSort("last_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "last_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                      Last Name
                    </TableSortLabel>
                    </TableCell>
              <TableCell sx={{color:'#fff'}}>
                    <TableSortLabel
                        active={orderBy === "email"}
                        direction={orderBy === "email" ? order : "asc"}
                        onClick={() => handleRequestSort("email")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "email" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >                                          
                Email
                </TableSortLabel>
                </TableCell>
              <TableCell sx={{color:'#fff'}}>
              <TableSortLabel
                        active={orderBy === "role"}
                        direction={orderBy === "role" ? order : "asc"}
                        onClick={() => handleRequestSort("role")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "role" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                Role
                </TableSortLabel>
                </TableCell>              
              <TableCell sx={{color:'#fff'}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {sortedRowInformation(
                users.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ),
                getComparator(order, orderBy)
              ).map((user, index) => (
              <TableRow key={user.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email}</TableCell>                
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  <IconButton onClick={()=>handleOpenDeleteDialog(user.id)}>
                    <Delete />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog open={parentDialogOpen} onClose={handleDialogClose} disableScrollLock={true} >
        <DialogContent>

          <AdminRegistraion onCloseParentDialog={handleDialogClose} onSuccess={fetchAdminUsers}/>

        </DialogContent>
      </Dialog>
      <Dialog
           open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this User?"}
          </DialogTitle>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDeleteDialog}>
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteUser(selectedUser)}
              color="secondary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      <Snackbar
        key={snackbarKey}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminUserControl;
