import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Typography, Box, CssBaseline, AppBar, Toolbar, IconButton, Grid, ListItemIcon,ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Dashboard from './Dashboard';
import BooksApproval from './BooksApproval';
import Books from './Books';
import CommentsControl from './CommentsControl';
import Users from './Users';
import ProfileSection from './ProfileSection';
import SearchSection from './SearchSection/SearchSection';
import BooksPending from './ApprovedBooks';
import AdminEditor from './AddAdminEditor';
import StoryBuzz from '../../assets/logonew.png'
import EventsAdd from './EventsAdd';
import UserProfile from './UserProfile';
import Slides from './Slides';
import { useLocation } from 'react-router-dom';
import Addschool from './AddSchool';
 
const drawerWidth = 240;
 
const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);
 
const AdminPanel = () => {
  const [value, setValue] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
 
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
 
  const handleChange = (index) => {
    localStorage.setItem('adminTab', index);
    setValue(index)
  }
  const location = useLocation();
  const prevTab = location.state?.prevTab || 0;
useEffect(() => {
  //console.log("Previous Tab Index:", prevTab);
  setValue(prevTab);  
}, [prevTab]);

useEffect(() => {
  const savedTab = localStorage.getItem('adminTab');
  if (savedTab !== null) {
    
    setValue(Number(savedTab));  
    
  } else {
    setValue(0);  
  }
}, []);
const drawer = (
  <Box>
    <List>
      <ListItem disableRipple sx={{ borderBottom: '1px solid #ccc' }}>
        <ListItemIcon onClick={() => handleChange(0)}>
          <img src={StoryBuzz} alt="Icon" style={{ width: 40, height: 40, cursor: "pointer" }} />
        </ListItemIcon>
        <ListItemText primary="Storybuzz" />
      </ListItem>
      <ListItemButton onClick={() => handleChange(0)}
         sx={{ backgroundColor: value === 0 ? '#1976d2' : 'transparent',
          color: value === 0? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===0 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => handleChange(1)}
       sx={{ backgroundColor: value === 1 ? '#1976d2' : 'transparent',
        color: value === 1? '#fff':'inherit',
        '&:hover':{
          backgroundColor: value===1 ?  '#3a84cf' : 'transparent',
        }
       }}
      >
        <ListItemText primary="Books" />
      </ListItemButton>
      <ListItemButton onClick={() => handleChange(2)}
         sx={{ backgroundColor: value === 2 ? '#1976d2' : 'transparent',
          color: value === 2? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===2 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="Books Approval" />
      </ListItemButton>
      <ListItemButton onClick={() => handleChange(3)}
         sx={{ backgroundColor: value === 3 ? '#1976d2' : 'transparent',
          color: value === 3? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===3 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="Approved Books " />
      </ListItemButton>
      <ListItemButton onClick={() => handleChange(4)}
         sx={{ backgroundColor: value === 4 ? '#1976d2' : 'transparent',
          color: value === 4? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===4 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="Comments Control" />
      </ListItemButton>
      <ListItemButton onClick={() => handleChange(5)}
         sx={{ backgroundColor: value === 5 ? '#1976d2' : 'transparent',
          color: value === 5? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===5 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="User" />
      </ListItemButton>
      <ListItemButton onClick={() => handleChange(6)}
         sx={{ backgroundColor: value === 6 ? '#1976d2' : 'transparent',
          color: value === 6? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===6 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="Add Admin/Editor" />
      </ListItemButton>

      <ListItemButton onClick={() => handleChange(10)}
         sx={{ backgroundColor: value === 10 ? '#1976d2' : 'transparent',
          color: value === 10? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===10 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="Add School" />
      </ListItemButton>


      <ListItemButton onClick={() => handleChange(7)}
         sx={{ backgroundColor: value === 7 ? '#1976d2' : 'transparent',
          color: value === 7? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===7 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="Events" />
      </ListItemButton>
      <ListItemButton onClick={() => handleChange(9)}
         sx={{ backgroundColor: value === 9 ? '#1976d2' : 'transparent',
          color: value === 9? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===9 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="Slides" />
      </ListItemButton>
      <ListItemButton onClick={() => handleChange(8)}
         sx={{ backgroundColor: value === 8 ? '#1976d2' : 'transparent',
          color: value === 8? '#fff':'inherit',
          '&:hover':{
            backgroundColor: value===8 ?  '#3a84cf' : 'transparent',
          }
         }}
        >
        <ListItemText primary="User Profile" />
      </ListItemButton>
    </List>
  </Box>
);
 
return (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar position="fixed" sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` } }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Grid container direction="row" sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography variant="h6" noWrap>
              Admin Panel
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              {/* <Grid item>
                <SearchSection />
                </Grid> */}
                <Grid item>
                  <Box sx={{ justifyContent: "flex-end" }}>
                    <ProfileSection />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        {/* </Grid> */}
      </Toolbar>
    </AppBar>
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
    >
      <Toolbar />
      <TabPanel value={value} index={0}>
        <Dashboard />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Books />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BooksApproval />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BooksPending />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CommentsControl />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Users />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <AdminEditor />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <EventsAdd />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Slides/>
      </TabPanel>
      <TabPanel value={value} index={8}>
        <UserProfile />
      </TabPanel>

      <TabPanel value={value} index={10}>
      <Addschool/>
      </TabPanel>
      
    </Box>
  </Box>
);
}
export default AdminPanel;