import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CircularProgress,
} from "@mui/material";
import { Add, Remove, Delete } from "@mui/icons-material";

export const ShoppingCartItem = ({
  book,
  handleIncrement,
  handleDecrement,
  handleDelete,
  handleBookClick = () => {},
  bookCounts,   
  ...sxProps
}) => {
  const bookCount = bookCounts[book.book_id] || 1;  

  return (
    <Card sx={{ display: "flex", border: "1px solid #B6B3B2", ...sxProps}}>
      <CardMedia
        component="img"
        sx={{ width: 150, objectFit: "cover", cursor: "pointer" }}
        image={`${process.env.REACT_APP_BOOK_COVER_BASE_URL}${book.cover_image_url}`}
        alt={book.title}
        onClick={() => handleBookClick(book)}
      />
      <Box sx={{ flex: 1, pl: 2, display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h6">{book.title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {book.author_name}
          </Typography>
          <Typography variant="body2"  mt={2}>
          Price: ${book.price}
          </Typography>
          <Typography variant="body2">
            Subtotal: ${(bookCounts[book.book_id] || 1) * book.price}
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", pt: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: { xs: "100px", sm: "120px" },
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "4px",
            }}
          >
            {bookCount === 1 ? (
              <IconButton onClick={() => handleDelete(book.book_id)} size="small">
                <Delete />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleDecrement(book.book_id)} size="small">
                <Remove />
              </IconButton>
            )}

            <Typography variant="body1" mx={1}>
              {bookCount}
            </Typography>

            <IconButton onClick={() => handleIncrement(book.book_id)} size="small">
              <Add />
            </IconButton>
          </Box>
        </CardActions>
      </Box>      
    </Card>
  );
};

